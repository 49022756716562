.m-0 {
  margin: 0 !important;
}

.mt-8 {
  margin-top: 3px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

$white: #fff;
$black: #000;
$green: #83db83;
$dark: #1c1c1e;
$lime: #00ffff;
$lightWhite: #e5e5e5;
$lightBlack: rgb(24, 22, 22);
$lightGreen: #68b184;
$lightDark: #808080;
$lightGrey: #534d4d;

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  height: 100%;
  width: 100%;
  position: fixed;
  background-image: url("./assets/images/login-background.webp");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(35 35 35 / 31%);
  }

  .login-wrapper {
    background-color: $white;
    display: flex;
    align-items: center;
    width: 470px !important;
    border-radius: 10px;
    overflow: hidden;
    background-color: $white !important;
    position: relative;
    box-shadow: 0px 1px 20px 0px rgb(0 0 0 / 55%);

    .login-form {
      display: flex;
      flex-direction: column;
      padding: 48px 32px;
      .login-icon-container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
        height: 50px;
      }
      form {
        ::placeholder {
          color: $white !important;
        }

        .ant-input-password {
          .ant-input {
            border: none !important;
            &:focus {
              border: none !important;
              box-shadow: none;
            }
          }
        }

        .ant-form-item {
          margin-bottom: 16px;
        }

        .ant-input {
          height: 40px;
          border-radius: 6px;
          &:not(.ant-input-status-error) {
            border-color: #d9d9d9;

            &:hover,
            &:active {
              border-color: #d9d9d9;
            }
          }

          &:focus {
            box-shadow: none;
          }
        }
      }

      .ant-input-affix-wrapper-focused {
        box-shadow: none !important;
      }

      .ant-input-affix-wrapper {
        padding: 0px 11px !important;
        border-radius: 6px;
        &:not(.ant-input-affix-wrapper-status-error) {
          border-color: #d9d9d9 !important;
        }

        &:focus {
          box-shadow: none !important;
        }
      }

      .ant-form-item-label {
        label {
          font-size: 14px;
          color: #333;
          line-height: 1.5;
        }
      }

      .ant-form-item-label {
        > label.ant-form-item-required {
          &:not(.ant-form-item-required-mark-optional) {
            &::before {
              position: absolute;
              right: -12px;
              top: 0px;
              font-size: 12px !important;
            }
          }
        }
      }

      h3 {
        display: block;
        font-size: 32px;
        color: #333;
        line-height: 1.2;
        text-align: center;
        margin-bottom: 32px;
        font-weight: 600;
      }
      h4 {
        color: #333;
        margin-bottom: 16px;
      }

      .remember-me {
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .ant-form-item {
          margin-bottom: 0 !important;
        }
        .forget-password-text {
          font-size: 14px;
          cursor: pointer;
          color: #666;
          &:hover {
            color: #1976d2;
          }
        }
        .ant-checkbox-wrapper {
          color: #666;
          &:hover {
            .ant-checkbox-inner {
              border-color: #1976d2;
            }
          }
          .ant-checkbox {
            &.ant-checkbox-checked {
              .ant-checkbox-inner {
                border-color: #1976d2;
                background-color: #1976d2;
              }
            }
          }
        }
      }

      .login-btn {
        border-radius: 6px;
        font-size: 1rem;
        font-weight: 600;
        color: $white;
        border: transparent;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        margin-top: 10px;
        background: #1976d2;
      }
    }
  }

  form {
    .ant-form-item {
      .ant-form-item-label {
        padding: 0 0 4px;
      }
    }
  }
}

.ant-modal-root {
  .ant-modal-wrap {
    .ant-modal-content {
      border-radius: 6px;
      .ant-modal-header {
        border-radius: 6px 6px 0 0;
        .ant-modal-title {
          font-size: 18px;
          font-weight: 500;
          line-height: 30px;
          letter-spacing: 0em;
          text-align: left;
          margin-right: 28px;
          .warning-header {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 10px;
            .warning-title {
              font-size: 18px;
              font-weight: 500;
              line-height: 30px;
              letter-spacing: 0em;
              margin-bottom: 0;
              color: #d14343;
              text-align: left;
              margin-top: 2.5px;
            }
            .warning-icon {
              span {
                height: 35px;
                width: 35px !important;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              display: flex;
              align-items: center;
              font-size: 18px;

              background: #fceeee;
              color: #d14343;
              border-radius: 50%;
              justify-content: center;
              outline: 5px solid #fdf4f4;
            }
          }
        }
      }
      .ant-modal-close {
        height: 63px;
        width: 63px;
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-modal-close-x {
          height: 35px;
          width: 35px;
         
          border-radius: 50%;
          position: relative;
          span {
            &.ant-modal-close-icon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
      .ant-modal-footer {
        padding: 16px 24px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .ant-btn-default {
          background: transparent;
         
          padding: 8px 24px;
          text-align: center;
          border-radius: 6px;
          span {
            display: flex;
            align-items: center;
            height: 100%;
          }
        }
        .ant-btn-default {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .ant-btn-primary {
         
          padding: 8px 24px;
          text-align: center;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            &:not(.ant-btn-loading-icon) {
              display: flex;
              align-items: center;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

.sign-up {
  .ant-select-focused {
    &:not(.ant-select-disabled).ant-select {
      &:not(.ant-select-customize-input) {
        .ant-select-selector {
          box-shadow: 0 0 5px 1px rgb(0 0 0 / 6%) !important;
          // border: 1px solid $inputBackround !important;
        }
      }
    }
  }
  .custom-scrollbar-row {
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 2px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: rgba(#7f93e2, 0.5);
    }
  }
  .ant-picker-focused {
    box-shadow: none !important;
    outline: 0;
  }
  .client-name {
    &:hover {
      color: #1976d2;
    }
    color: white;
  }
  .ant-picker-focused,
  .ant-picker:hover {
    border-color: #d9d9d9 !important;
    border-right-width: 1px;
  }
  .date-picker-signup {
    width: 100%;
    border-radius: 6px;
    height: 40px !important;
  }
  .ant-form-item-explain,
  .ant-form-item-extra {
    font-size: 12px !important;
  }
  .login-wrapper {
    width: 700px !important;
    height: 92vh !important;
    .login-form {
      padding: 12px 30px;
    }
    .login-form .login-icon-container {
      margin-bottom: 14px !important;
    }
    .login-form .ant-form-item-label label {
      font-size: 12px !important;
    }
    .login-form form .ant-form-item {
      margin-bottom: 10px !important;
    }
    .ant-select {
      .ant-select-selector {
        border-radius: 6px;
        height: 40px;
        align-items: center;
        justify-content: center;
        padding: 5px 15px;
        &:hover {
          border-color: #d9d9d9;
        }
      }
    }
    // form
    //   .ant-form-item
    //   .ant-form-item-control
    //   .ant-form-item-control-input
    //   .ant-form-item-control-input-content
    //   .ant-input {
    //   min-height: 32px !important;
    //   height: 32px !important;
    //   font-size: 12.5px !important;
    // }
    // form
    //   .ant-form-item
    //   .ant-form-item-control
    //   .ant-form-item-control-input
    //   .ant-form-item-control-input-content
    //   .ant-input-password {
    //   min-height: 34px !important;
    //   height: 34px !important;
    //   font-size: 12.5px !important;
    // }
    .ant-col-24.ant-form-item-label,
    .ant-col-xl-24.ant-form-item-label,
    .ant-form-vertical .ant-form-item-label {
      padding: 0px !important;
    }
  }
}

.light {
  $primaryColor: white;
  $lightPrimaryColor: white;
  $menuItemColors: #a5a5a5;
  $menuItemsHovor: #4768e9;
  $layoutBackground: #e0e0e0;
  $tableActionColor: #2143c9;
  $paginationBackground: #7f93e2;
  $paginationBackgroundActive: #4768e9;
  $primaryTextColor: #000;
  $secondaryTextColor: #fff;
  $inputBackround: #e6e6e6;
  $tabsActive: #385ce8;
  $buttonBackground: #4768e9;
  $tableBackground: #fff;
  $headerInputBackground: #fff;
  $labelColor: #000;
  $tagColor: #4768e9;
  $drawerHeaderBackground: #4768e9;
  $drawerBodyBackground: #fff;
  // $elementTableBackround: #f8f8f8;
  $elementTableInputBackground: $white;
  $elementTableBackround: $white;
  $activeRowColor: #4768e9;
  $clientSpecificRowColor: #f0f0f0;
  $cancelButtonBackground: #534d4d;
  $breadCrumbLinkColor: #000;
  $formLayoutBackground: #fff;
  $scrollBarThumbColor: #7f93e2;
  $scrollBarTrackColor: #fff;
  // $tableCellBorderColor: #d6d6d6;
  $tableCellBorderColor: #b4b4b4;

  $switchUncheckedColor: #7f93e2;
  $switchCheckedColor: #2143c9;
  $mappedFieldColor: #4caf4c;
  $unmappedFieldColor: #d93a3a;
  $statusButtonActiveColor: #4768e9;
  $statusButtonActiveBorderColor: $white;
  $cancelButtonBackground: #747474;

  @import "_main.scss";
}

.dark {
  $primaryColor: #141414;
  $lightPrimaryColor: #1f1f1f;
  $menuItemColors: hsla(0, 0%, 100%, 0.65);
  $menuItemsHovor: #fff;
  $layoutBackground: #000;
  $tableActionColor: #fff;
  $paginationBackground: #534d4d;
  $primaryTextColor: #fff;
  $secondaryTextColor: #000;
  $paginationBackgroundActive: #1c1c1e;
  $inputBackround: #000;
  $tabsActive: #83db83;
  $buttonBackground: #534d4d;
  $tableBackground: #292929;
  $headerInputBackground: #534d4d;
  $labelColor: #fff;
  $tagColor: #534d4d;
  $drawerHeaderBackground: #534d4d;
  $drawerBodyBackground: #1f1f1fed;
  $elementTableBackround: #292929;
  $elementTableInputBackground: $elementTableBackround;
  $activeRowColor: #68b184;
  $clientSpecificRowColor: #635e5e;
  $cancelButtonBackground: #000;
  $breadCrumbLinkColor: #a6a6a6;
  $formLayoutBackground: #1f1f1f;
  $scrollBarThumbColor: #534d4d;
  $scrollBarTrackColor: #1c1c1e;
  $tableCellBorderColor: #535353;
  $switchUncheckedColor: #534d4d;
  $switchCheckedColor: #000;
  $mappedFieldColor: #4caf4c;
  $unmappedFieldColor: #d93a3a;
  $statusButtonActiveColor: #000;
  $statusButtonActiveBorderColor: $green;
  $cancelButtonBackground: $black;

  @import "_main.scss";
}

body {
  font-family: "Poppins", sans-serif !important;
}
.new-light {
  font-family: "Poppins", sans-serif !important;
  $tableCellFontSize: 13px;
  $sidebarColor: $white;
  $sidebarInactiveTextColor: #777777;
  $primaryTextColor: #49454f;
  $secondaryTextColor: #1976d2;
  // $layoutBackground: #f4f4f4;
  $layoutBackground: #ecebeb;
  $tableRowColor: #ecf3f9;
  $inputBorderColor: #d9d9d9;
  $primaryBorderColor: #f0f0f0;
  $elementTableBorderColor: #e2e2e2;
  $scrollBarThumbColor: $secondaryTextColor;
  $scrollBarTrackColor: #fff;

  @import "_new-main.scss";
}

@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}
.loader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: rgb(0 0 0 / 70%);
  width: 100%;
  height: 100%;
  > img {
    transform: translate(-50%, -50%);
    -webkit-animation: breathing 2.5s ease-out infinite normal;
    animation: breathing 2.5s ease-out infinite normal;
    -webkit-font-smoothing: antialiased;
  }
}

// Transition disabled for the theme toggling
.ant-layout-sider {
  transition: all 0.2s, background 0s !important;
}

.ant-btn {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0s,
    border-color 0s, color 0s, border 0s !important;
}

.ant-table-tbody {
  > tr {
    > td {
      transition: background 0s, background-color 0s !important;
    }
  }
}
.blur-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(3px) !important;
}
.modal-container {
  z-index: 10000; /* Ensure this is above the blur overlay */
  position: relative;
}
.ant-table-thead {
  > tr {
    > th {
      transition: background 0s ease !important;
      &:not(:last-child) {
        &:not(.ant-table-selection-column) {
          &:not(.ant-table-row-expand-icon-cell) {
            &:not([colspan]) {
              &::before {
                transition: background-color 0s !important;
              }
            }
          }
        }
      }
    }
  }
}

.ant-tag {
  transition: all 0.3s, background 0s !important;
}

.ant-select {
  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0s,
        color 0s, border-color 0s !important;
    }
  }
}

.ant-select-single {
  .ant-select-selector {
    .ant-select-selection-item {
      transition: all 0.3s, color 0s !important;
    }
    .ant-select-selection-placeholder {
      transition: all 0.3s, color 0s !important;
    }
  }
}

.trigger {
  transition: color 0s !important;
}

.ant-menu-item {
  .ant-menu-item-icon {
    transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
      margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0s !important;
    + {
      span {
        transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
          margin 0.3s, color 0s !important;
      }
    }
  }
  .anticon {
    transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
      margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0s !important;
    + {
      span {
        transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
          margin 0.3s, color 0s !important;
      }
    }
  }
}

.ant-input {
  transition: all 0.3s, background 0s, color 0s, border-color 0s !important;
}

.ant-pagination-prev {
  .ant-pagination-item-link {
    transition: all 0.3s, background 0s !important;
  }
}
.ant-pagination-next {
  .ant-pagination-item-link {
    transition: all 0.3s, background 0s !important;
  }
}

.ant-switch {
  transition: all 0.2s, background-color 0s !important;
}

.ant-tabs-tab-btn {
  transition: all 0.3s, color 0s !important;
}

.ant-collapse-header {
  transition: all 0.3s, color 0s !important;
}

.ant-picker {
  transition: all 0s !important;
}
