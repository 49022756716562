.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  color: $breadCrumbLinkColor !important;
}
.logo {
  height: 32px;
  margin: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 70px;
  }
}

.ant-layout {
  height: 100vh;
  background: $layoutBackground !important;
  .site-layout-background-wrapper {
    // height: calc(100vh - 75px);
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.site-layout {
  header.ant-layout-header {
    background: $lightPrimaryColor !important;
    z-index: 2;
    box-shadow: 0 0 9px 1px rgb(0 0 0 / 10%);
  }

  .site-layout-background-wrapper {
    padding: 10px;
  }

  .site-layout-background {
    padding: 30px;
    color: $primaryTextColor;
  }
  main.site-layout-background {
    min-height: calc(100vh - 115px) !important;
  }
}

.ant-layout-sider {
  background: $primaryColor !important;

  .ant-menu {
    font-size: 16px !important;
    font-weight: 600;
    .ant-menu-item {
      color: $menuItemColors !important;
    }
    .ant-menu-submenu {
      .ant-menu-submenu-title {
        color: $menuItemColors !important;
      }
    }
    i {
      font-size: 16px !important;
      font-weight: 600 !important;
    }
  }

  .ant-menu-item-selected {
    background-color: transparent !important;
  }

  .ant-menu-dark {
    .ant-menu-submenu-title {
      .ant-menu-submenu-arrow {
        &:after,
        &:before {
          background: $menuItemColors;
        }
      }
    }

    .ant-menu-submenu-selected,
    .ant-menu-submenu-active {
      .ant-menu-submenu-title {
        .ant-menu-item-icon,
        span {
          color: $menuItemsHovor;
        }
      }
    }

    .ant-menu-item-selected,
    .ant-menu-item-active {
      .ant-menu-submenu-arrow {
        &:after,
        &:before {
          background: $menuItemsHovor;
        }
      }
      .ant-menu-item-icon,
      span {
        color: $menuItemsHovor;
      }
    }
  }

  .ant-menu {
    background: inherit !important;
  }
  .ant-menu-inline {
    .ant-menu-item {
      &:after {
        border-right: 3px solid $menuItemsHovor !important;
      }
    }
  }
}

.form-layout {
  background: $formLayoutBackground;
  padding: 30px;
  border-radius: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header-left {
    display: flex;
    align-items: center;
    .trigger {
      display: flex;
      align-items: center;
    }
    color: $menuItemColors !important;
  }

  .header-right {
    .user-header {
      display: flex;
      align-items: center;
      color: white;
      .ant-space {
        .ant-space-item {
          cursor: pointer;
        }
      }
      .drop-down {
        color: $primaryTextColor;
        font-weight: 600;
      }
    }
    margin-right: 50px;
    .switch-wrap {
      margin-right: 15px;
      i {
        color: $primaryTextColor;
      }
    }
  }
}

.ant-breadcrumb {
  li {
    color: $menuItemsHovor !important;
    font-size: 16px;
    font-weight: 600 !important;
    span {
      cursor: pointer;
    }
    .disabled-link {
      color: $breadCrumbLinkColor;
      cursor: default;
    }
  }
  .ant-breadcrumb-separator {
    color: $breadCrumbLinkColor;
  }
}

.section-form {
  .ant-table {
    .ant-table-content {
      .ant-table-tbody {
        .ant-table-cell {
          padding: 2px !important;
        }
      }
    }
  }
}

.ant-table {
  font-size: 0.85rem !important;
  border-radius: 10px !important;
  overflow: hidden;
  .ant-table-content {
    background: $tableBackground !important;

    color: $primaryTextColor;
    .ant-table-thead {
      > tr {
        > th {
          font-weight: bold;
          background: $tableBackground !important;
          border-color: $tableCellBorderColor !important;
          color: $primaryTextColor;
          &::before {
            background: $menuItemsHovor !important;
          }
        }
      }
    }

    .ant-table-tbody {
      cursor: pointer;
      .ant-table-cell {
        padding: 10px 16px !important;
      }

      tr:not(:last-child, .section-form, .last-row) {
        .ant-table-cell {
          border-bottom: 1px solid $tableCellBorderColor !important;
        }
      }

      .ant-table-row {
        .ant-table-cell {
          .table-tag-list {
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }
      }

      > tr.ant-table-placeholder {
        > td.ant-table-cell {
          > div.ant-empty-normal {
            color: $primaryTextColor !important;
            .ant-empty-description {
              color: $primaryTextColor !important;
            }
          }
        }
        &:hover:not(.section-form) {
          > td {
            background: $tableBackground !important;
          }
        }
      }
      .table-action-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          color: $tableActionColor;
          padding: 6px 10px;
        }
      }

      .ant-table-row:not(.section-form) {
        .ant-table-cell-row-hover {
          background: $tableBackground !important;
        }
      }

      > tr {
        > td {
          border-color: transparent !important;
        }
      }
    }
  }

  tr.ant-table-expanded-row {
    &:hover {
      > td {
        background: $tableBackground;
      }
    }
    > td {
      background: $tableBackground;
    }
  }
}

.table-striped-rows {
  tr {
    &:nth-child(even) {
      td {
        background-color: $primaryColor !important;
      }
    }
  }
}

.site-layout-background {
  .listing-header {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;

    .listing-header-left {
      display: flex;
      align-items: center;
      .active {
        border: 2px solid $statusButtonActiveBorderColor;
        background-color: $statusButtonActiveColor;
      }

      .default {
        background-color: $paginationBackground;
      }

      button {
        height: 35px !important;
        border-radius: 40px !important;
        border: none;
        padding: 4px 20px !important;
        font-weight: 600 !important;
      }
      .dropdown-filter {
        .ant-select {
          color: $primaryTextColor;
          .ant-select-selector {
            background-color: $headerInputBackground;
            border: 1px solid $headerInputBackground;
            border-radius: 40px;
            height: 40px;
            width: 200px;
            align-items: center;
            justify-content: center;
            padding: 5px 15px;
            &:hover {
              border-color: $headerInputBackground;
            }
          }

          i {
            color: $primaryTextColor;
            font-size: 20px;
          }

          .ant-select-arrow {
            color: $primaryTextColor !important;
          }
        }
        .ant-select-focused {
          &:not(.ant-select-disabled).ant-select {
            &:not(.ant-select-customize-input) {
              .ant-select-selector {
                box-shadow: 0 0 5px 1px rgb(0 0 0 / 6%) !important;
                border: 1px solid $inputBackround !important;
              }
            }
          }
        }
      }
    }

    .listing-header-right {
      display: flex;
      align-items: center;
      .dropdown-filter {
        margin-left: 25px;
        margin-right: 25px;
        .ant-select {
          color: $primaryTextColor;
          .ant-select-selector {
            background-color: $headerInputBackground;
            border: 1px solid $headerInputBackground;
            border-radius: 40px;
            height: 40px;
            width: 200px;
            align-items: center;
            justify-content: center;
            padding: 5px 15px;
            &:hover {
              border-color: $headerInputBackground;
            }
          }

          i {
            color: $primaryTextColor;
            font-size: 20px;
          }

          .ant-select-arrow {
            color: $primaryTextColor !important;
          }
        }
        .ant-select-focused {
          &:not(.ant-select-disabled).ant-select {
            &:not(.ant-select-customize-input) {
              .ant-select-selector {
                box-shadow: 0 0 5px 1px rgb(0 0 0 / 6%) !important;
                border: 1px solid $inputBackround !important;
              }
            }
          }
        }
      }
      .dropdown-filter-noRight {
        margin-left: 25px;
        .ant-select {
          color: $primaryTextColor;
          .ant-select-selector {
            background-color: $headerInputBackground;
            border: 1px solid $headerInputBackground;
            border-radius: 40px;
            height: 40px;
            width: 200px;
            align-items: center;
            justify-content: center;
            padding: 5px 15px;
            &:hover {
              border-color: $headerInputBackground;
            }
          }

          i {
            color: $primaryTextColor;
            font-size: 20px;
          }

          .ant-select-arrow {
            color: $primaryTextColor !important;
          }
        }
        .ant-select-focused {
          &:not(.ant-select-disabled).ant-select {
            &:not(.ant-select-customize-input) {
              .ant-select-selector {
                box-shadow: 0 0 5px 1px rgb(0 0 0 / 6%) !important;
                border: 1px solid $inputBackround !important;
              }
            }
          }
        }
      }
    }
  }
}

.ant-pagination {
  button,
  .ant-pagination-item {
    color: $white !important;
    background: $paginationBackground !important;
    border-color: transparent !important;
    border-radius: 50% !important;
    a {
      color: $white !important;
    }
  }
  .ant-pagination-jump-next {
    .ant-pagination-item-link {
      .ant-pagination-item-container {
        .ant-pagination-item-link-icon {
          color: $paginationBackground !important;
        }
      }
    }
  }
  .ant-pagination-item-active {
    color: $white !important;
    background: $paginationBackgroundActive !important;
  }
}

.add-btn {
  background-color: $buttonBackground !important;
  color: $white !important;
  font-weight: 700 !important;
  height: 35px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: none !important;
  border-radius: 10px !important;
  padding: 0 12px !important;
  i {
    margin-right: 10px !important;
    font-size: 14px !important;
  }
  &:hover,
  &:active {
    background-color: $buttonBackground !important;
  }
}

.add-btn-disabled {
  background-color: rgba(68, 65, 65, 0.774) !important;
}

.ant-tag {
  background: $tagColor !important;
  border-color: transparent !important;
  color: $white !important;
  border-radius: 6px !important;
  font-size: 13px !important;
  padding: 2px 10px !important;
  margin-right: 0 !important;
  cursor: pointer;
}

.ant-tag-default {
  background: $mappedFieldColor !important;
}

.slim-scrollbar {
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: $scrollBarTrackColor;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $scrollBarThumbColor;
  }
}

.custom-text-input {
  background-color: $headerInputBackground;
  border: 1px solid $headerInputBackground;
  border-radius: 40px !important;
  height: 40px !important;
  width: 200px !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 5px 15px !important;
  color: $primaryTextColor;
  &:focus,
  &:hover,
  &:active {
    box-shadow: none !important;
    border-color: $headerInputBackground !important;
  }
}

.form-title-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px !important;
  .ant-form-item-explain-error {
    width: 250px;
  }
  h2.form-title {
    font-size: 16px !important;
  }
  .form-title {
    font-size: 20px !important;
    color: $primaryTextColor !important;
    margin-bottom: 0px !important;
    font-weight: 600 !important;
    padding: 0 !important;
    text-transform: capitalize;
  }
}

.section-title-row {
  width: 100%;
  margin-left: 12px;
  margin-bottom: 10px;
  .section-title {
    font-size: 16px;
    color: $primaryTextColor;
    font-weight: 600;
    text-transform: capitalize;
  }
}

form {
  .form-help-text {
    margin-top: 5px;
    color: $primaryTextColor;
    font-weight: bold;
    i {
      margin-right: 10px;
    }
  }

  .ant-form-item-label {
    label {
      color: $labelColor !important;
      font-weight: 600;
    }
  }
  .ant-form-item-label {
    > label.ant-form-item-required {
      &:not(.ant-form-item-required-mark-optional) {
        &::before {
          position: absolute;
          right: -12px;
          top: 0px;
          font-size: 12px !important;
          color: red;
        }
      }
    }
  }

  input {
    ::placeholder {
      color: $labelColor !important;
    }
  }

  .phone-input {
    letter-spacing: 2px;
  }

  .ant-select-selection-placeholder {
    color: $labelColor;
  }
  textarea.ant-input {
    height: auto !important;
  }
  .ant-input,
  .ant-select-selector,
  .ant-picker {
    height: 48px !important;
    background-color: $inputBackround !important;
    border: transparent !important;
    border-radius: 10px !important;
    color: $primaryTextColor !important;
    width: 100%;
    &:focus {
      box-shadow: none !important;
      background-color: $inputBackround !important;
    }
    span.ant-select-selection-placeholder {
      line-height: 50px !important;
    }
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }
  .ant-input,
  .ant-select-selector,
  .ant-picker {
    height: 48px !important;
    background-color: $inputBackround !important;
    border: transparent !important;
    border-radius: 10px !important;
    color: $primaryTextColor !important;
    width: 100%;
    &:focus {
      box-shadow: none !important;
      background-color: $inputBackround !important;
    }
    span.ant-select-selection-placeholder {
      line-height: 50px !important;
    }
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }

  .ant-select {
    &.ant-select-multiple {
      &.apply-overflow {
        .ant-select-selector {
          scrollbar-width: thin;
          &::-webkit-scrollbar {
            width: 8px;
          }
          &::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: $scrollBarTrackColor;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: $scrollBarThumbColor;
          }
          overflow-y: auto;
        }
      }
      .ant-select-selector {
        min-height: 48px !important;
        height: 100% !important;
        max-height: 350px;
        .ant-select-selection-placeholder {
          padding: 5px;
        }
        .ant-select-selection-overflow {
          padding: 5px;
          overflow: auto;
          .ant-select-selection-overflow-item {
            .ant-select-selection-item {
              background: $tagColor;
              padding: 15px 10px !important;
              color: $white;
              border-radius: 10px !important;
              border-color: transparent !important;
              .ant-select-selection-item-content {
                color: $white;
              }
              .ant-select-selection-item-remove {
                color: $white;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  .ant-select-arrow {
    color: $primaryTextColor !important;
  }
  .ant-select-focused {
    .ant-select-selector {
      border: none;
      box-shadow: none !important;
      background-color: $inputBackround !important;
    }
  }

  .ant-form-item {
    margin-bottom: 10px;
    input,
    textarea {
      &::placeholder {
        color: $primaryTextColor;
      }
    }
  }
  .ant-form-item-label {
    margin: 0;
    padding: 0 !important;
    text-transform: capitalize;
    font-size: 13px !important;
    label {
      font-size: 13px;
      font-weight: 600;
      color: $labelColor !important;
      position: relative;
      height: auto;
    }
  }

  .ant-form-item {
    margin-bottom: 15px !important;
  }
  .ant-input-affix-wrapper {
    background-color: $inputBackround !important;
    border: transparent !important;
    border-radius: 10px !important;
    padding: 0px;
    .ant-input-password-icon {
      color: $primaryTextColor !important;
    }
    input.ant-input {
      padding: 0px 10px;
    }
    .ant-input-suffix {
      padding-right: 10px;
      border-radius: 10px !important;
    }
  }
  .ant-input-affix-wrapper-focused {
    box-shadow: none !important;
    background-color: $inputBackround !important;
  }
  .ant-input-status-error {
    &:not(.ant-input-disabled) {
      &:not(.ant-input-borderless).ant-input {
        background: $inputBackround !important;
        border: 1px solid red !important;
      }
    }
  }
  .ant-select-status-error.ant-select {
    &:not(.ant-select-disabled) {
      &:not(.ant-select-customize-input) {
        .ant-select-selector {
          border: 1px solid red !important;
        }
      }
    }
  }
}

.switch-wrap {
  .ant-switch:focus {
    box-shadow: none !important;
  }
  .ant-switch {
    background-color: $switchUncheckedColor;
    background-image: none;
  }
  .ant-form-item-control {
    align-items: center;
    flex-direction: row;
    min-height: 48px;
    display: flex;
  }
  .ant-switch-checked {
    background-color: $switchCheckedColor;
    .ant-switch-handle {
      &::before {
        background-color: $white;
      }
    }
  }
}

.cancel-btn {
  margin-right: 15px;
  height: 40px !important;
  background: $cancelButtonBackground !important;
  color: $white !important;
  border-color: transparent !important;
  border-radius: 6px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.save-btn {
  height: 40px !important;
  width: 80px !important;
  background: $buttonBackground !important;
  color: $white !important;
  border-color: transparent !important;
  border-radius: 6px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.save-continue-btn {
  height: 40px !important;
  margin-right: 10px;
  width: 150px !important;
  background: $buttonBackground !important;
  color: $white !important;
  border-color: transparent !important;
  border-radius: 6px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.edit-btn {
  background: $buttonBackground !important;
  border-color: transparent !important;
  border-radius: 6px !important;
  color: $white !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  height: 40px !important;
  width: 100px !important;
}

.form-item {
  margin-bottom: 15px !important;
  label {
    font-size: 13px;
    font-weight: 600;
    color: $primaryTextColor;
    position: relative;
    text-transform: capitalize;
  }
  p {
    font-size: 16px;
  }
}

.confirmation-popup {
  &.without-title {
    .ant-modal-content {
      .ant-modal-body {
        margin-right: 10px;
      }
    }
  }
  .ant-modal-content {
    border-radius: 8px;
    .ant-modal-header {
      border-radius: 8px 8px 0 0;
    }
    .ant-modal-footer {
      padding: 15px 24px;
      button.ant-btn {
        border: none;
        box-shadow: none;
        text-transform: uppercase;
        font-weight: 700;
      }

      .ant-btn-default {
        border-radius: 12px;
        background: $cancelButtonBackground;
        color: $white;
        font-weight: 700;
        height: 45px;
        min-width: 70px;
        color: $white !important;
      }

      .ant-btn-primary {
        background-color: $buttonBackground;
        color: $white !important;
        text-transform: uppercase;
        font-weight: 700;
        height: 45px;
        border: none;
        border-radius: 12px;
        min-width: 100px;
        &:hover {
          background-color: $buttonBackground;
          opacity: 0.8;
        }
      }
    }
  }
}
.ant-table-tbody {
  > tr.ant-table-row:not(.client-specific-row, .active-table-row) {
    &:hover {
      > td {
        background: $tableBackground;
      }
    }
  }
  > tr:not(.section-form) {
    > td.ant-table-cell-row-hover {
      background: $tableBackground;
    }
  }
}

.provider-statuses {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  .ant-tag {
    display: flex;
    align-items: center;
    margin: 0 6px 6px 0 !important;
    justify-content: space-between;
    max-width: inherit;
    p {
      margin: 0 3px 0 0 !important;
    }
  }
}

.info-text {
  color: $primaryTextColor !important;
  .fas {
    margin-right: 5px;
    font-size: 16px;
  }
}

.ant-select-selection-search {
  display: flex;
  align-items: center;
}

.ant-select-clear {
  background-color: $inputBackround !important;
  color: $primaryTextColor !important;
}

.ant-drawer-content-wrapper {
  .ant-drawer-wrapper-body {
    .ant-drawer-header {
      background: $drawerHeaderBackground;
      border-radius: 0;
      .ant-drawer-header-title {
        button {
          color: $white;
          position: absolute;
          right: 0;
        }
        .ant-drawer-title {
          color: $white;
          font-weight: 600;
        }
      }
    }
  }
}

.add-section-form {
  .ant-form-item-label {
    label {
      color: $inputBackround !important;
    }
  }
  .add-row-btn {
    color: $buttonBackground !important;
    font-weight: 600 !important;
    margin: 0px 0 15px 0;
    box-shadow: none !important;
  }
}

.add-row-btn {
  color: $buttonBackground !important;
  font-weight: 600 !important;
  margin: 0px 0 15px 0;
  box-shadow: none !important;
  border: none;
  // background: transparent;
}

.ant-drawer-close {
  transition: color 0s;
}

.add-section-drawer {
  .ant-form-item-label {
    margin: 0;
    padding: 0 !important;
    text-transform: capitalize;
    font-size: 13px !important;
    label {
      font-size: 13px;
      font-weight: 600;
      color: #c7c7c7 !important;
      position: relative;
      height: auto;
    }
  }
  .drag-icon {
    color: $primaryTextColor;
    margin: 0 10px;
  }
}
.add-partner-fields-drawer {
  .ant-form-item-label {
    label {
      color: #000000 !important;
    }
  }
}
.add-section-drawer {
  .ant-drawer-header {
    border-bottom: none;
  }
  .ant-drawer-body {
    padding: 0px;
    overflow: hidden;
    background: $drawerBodyBackground;
    form {
      height: 90%;
    }
    .add-section-form-wrapper {
      overflow-x: auto;
      overflow-y: auto;
      height: 95% !important;
      .add-field-btn {
        padding-top: 20px;
        position: sticky;
        top: 0;
        z-index: 1000;
        background: #fff;
      }
      &::-webkit-scrollbar {
        height: 8px;
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: $scrollBarTrackColor !important;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $scrollBarThumbColor !important;
      }
    }
  }
  .add-section-actions {
    padding: 16px;
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 15px;
  }
}

.question-form-wrapper {
  .empty-section {
    text-align: center;
    h2 {
      color: $buttonBackground;
      font-weight: 600;
    }
    h3,
    h4 {
      color: $buttonBackground;
      font-weight: 600;
    }
    button {
      margin-top: 20px;
    }
  }
  .add-element-btn {
    background: transparent;
    color: $primaryTextColor !important;
    border: none;
    font-weight: 600 !important;
    box-shadow: none !important;
    .ant-icon {
      font-size: 16px !important;
    }
    &:active {
      background: transparent !important;
    }
    &:focus {
      background: transparent !important;
    }
    &:hover {
      background: transparent !important;
    }
  }
  .ant-divider {
    border-top: 2px solid $primaryColor !important;
  }
  p.alert-error {
    color: red;
    margin-top: -10px;
  }
  .section-options {
    margin-right: 5px !important;
    margin-bottom: 5px !important;
  }
}

p.alert-error {
  color: #ff4d4f;
  margin-top: -10px;
}

.section-option-add {
  .ant-select-selector {
    height: 100% !important;
    min-height: 100% !important;
    padding: 10px 8px !important;
  }
}

.question-provider-form-wrapper {
  display: flex;
  flex-direction: column;
  .title-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px !important;
    div.title {
      h2 {
        font-size: 16px !important;
        color: $primaryTextColor !important;
        margin-bottom: 0px !important;
        font-weight: 600 !important;
        padding: 0 !important;
      }
    }
  }
}

.question-section-form-wrapper {
  .empty-section {
    margin-top: 50px;
    text-align: center;
    h2 {
      color: $buttonBackground;
      font-weight: 600;
    }
    h3,
    h4 {
      color: $buttonBackground;
      font-weight: 600;
    }
    button {
      margin-top: 20px;
    }
  }
  display: flex;
  flex-direction: column;
  .title-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px !important;
    div.title {
      h2 {
        font-size: 16px !important;
        color: $primaryTextColor !important;
        margin-bottom: 0px !important;
        font-weight: 600 !important;
        padding: 0 !important;
      }
    }
  }

  .ant-tabs {
    color: $white;
    .ant-tabs-nav {
      &::before {
        border-bottom: 1px solid $tabsActive;
      }
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          .ant-tabs-tab-btn {
            color: $primaryTextColor !important;
            font-weight: bold;
            text-transform: capitalize;
            &:hover {
              color: $tabsActive !important;
            }
          }
        }
        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: $tabsActive !important;
            text-transform: capitalize;
            font-weight: 600;
          }
        }
        .ant-tabs-ink-bar {
          background: $tabsActive !important;
          height: 2.5px !important;
        }
      }
    }
  }
}

.add-section-btn {
  border: none !important;
  width: 100% !important;
  background: $buttonBackground !important;
  color: $white !important;
  height: 40px !important;
  border-radius: 6px !important;
  font-weight: 600 !important;
  padding: 25px 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  font-size: 16px !important;
  margin-bottom: 10px;
  i {
    margin-right: 10px;
  }
}

.form-row {
  display: flex;
  align-items: center;
}

.section-form,
.field-map-table-form {
  .elements-table-wrapper {
    display: flex;
    justify-content: center;
  }
  .section-extras {
    .toggle-btn {
      margin-right: 10px;
    }
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
  }
  .ant-select-status-error.ant-select {
    &:not(.ant-select-disabled) {
      &:not(.ant-select-customize-input) {
        .ant-select-selector {
          border: 1px solid red !important;
        }
      }
    }
  }

  .ant-table.ant-table-bordered {
    > .ant-table-container {
      border-left: 1px solid $tableCellBorderColor;
    }
  }

  .ant-table {
    .ant-input,
    .ant-select-selector {
      background: $elementTableInputBackground !important;
    }

    background: $elementTableBackround !important;
    border-radius: 0 !important;

    .ant-table-content {
      background: $elementTableBackround !important;
      scrollbar-width: thin;
      color: $primaryTextColor;
      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: $scrollBarTrackColor;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $scrollBarThumbColor;
      }

      .ant-form-item-explain {
        min-height: 1px;
        color: $primaryTextColor;
        .ant-form-item-explain-success,
        .ant-form-item-explain-error {
          font-size: 12px;
        }
      }

      table {
        border-color: $tableCellBorderColor !important;
        // border: 1px solid $tableCellBorderColor) !important;
        // border-left: 1px solid $tableCellBorderColor) !important;
      }

      .ant-table-thead {
        > tr {
          > th {
            padding: 7px 16px;
            // border-right: none !important;
            font-weight: bold;
            background: $elementTableBackround !important;
            border-color: $tableCellBorderColor !important;
            color: $primaryTextColor;
            &:not(:last-child) {
              &:not(.ant-table-selection-column) {
                &:not(.ant-table-row-expand-icon-cell) {
                  &:not([colspan]) {
                    &::before {
                      content: none !important;
                    }
                  }
                }
              }
            }
            &::before {
              width: 2px !important;
              height: 30px !important;
              background-color: $buttonBackground !important;
            }
          }
        }
      }

      .ant-table-tbody {
        cursor: default;
        .empty-table-cell {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
        }
        .tooltip-wrapper {
          height: 40px;
        }
        .ant-form-item {
          margin-bottom: 0 !important;
          .ant-form-item-control {
            height: 40px !important;
          }

          .ant-form-item-explain {
            .ant-form-item-explain-error {
              display: none;
            }
          }

          .ant-input,
          .ant-select-selector,
          .ant-picker {
            border-radius: 0 !important;
            font-size: 14px !important;
            height: 40px !important;
            align-items: center;
            justify-content: center;
          }
          .ant-input-status-error {
            &:not(.ant-input-disabled) {
              &:not(.ant-input-borderless).ant-input {
                background: $elementTableInputBackground !important;
                border: 1px solid red !important;
              }
            }
          }
        }

        tr.active-table-row {
          background-color: $activeRowColor !important;
          .ant-input,
          .ant-select-selector {
            background-color: $activeRowColor !important;
          }
          .ant-table-cell-fix-left {
            background-color: $activeRowColor !important;
          }
          .ant-table-cell-row-hover {
            background: $activeRowColor !important;
          }
        }

        tr.client-specific-row {
          background-color: $clientSpecificRowColor !important;
          .ant-input,
          .ant-select-selector {
            background-color: $clientSpecificRowColor !important;
          }
          .ant-table-cell-fix-left {
            background-color: $clientSpecificRowColor !important;
          }
          .ant-table-cell-row-hover {
            background: $clientSpecificRowColor !important;
          }
        }

        tr.unmapped-row {
          background-color: $unmappedFieldColor !important;
          .ant-input,
          .ant-select-selector {
            background-color: $unmappedFieldColor !important;
          }
          .ant-table-cell-fix-left {
            background-color: $unmappedFieldColor !important;
          }
          .ant-table-cell-row-hover {
            background: $unmappedFieldColor !important;
          }
        }

        tr.mapped-row {
          background-color: $mappedFieldColor !important;

          .ant-input,
          .ant-select-selector {
            background-color: $mappedFieldColor !important;
          }
          .ant-table-cell-fix-left {
            background-color: $mappedFieldColor !important;
          }
          .ant-table-cell-row-hover {
            background: $mappedFieldColor !important;
          }
        }

        > tr.ant-table-row {
          &:hover {
            > td {
              background: transparent !important;
            }
          }
        }
        > tr {
          > td.ant-table-cell-row-hover {
            background: transparent !important;
          }
        }

        tr {
          &:last-child {
            .ant-table-cell {
              border-bottom: 1px solid $tableCellBorderColor !important;
              border-right: 1px solid $tableCellBorderColor !important;
            }
          }
        }

        td.ant-table-cell {
          // padding: 1px !important;
          padding: 0 !important;
          // border-right: none !important;
          .switch-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            .ant-col {
              justify-content: center;
            }
          }
        }
        > tr.ant-table-placeholder {
          > td.ant-table-cell {
            > div.ant-empty-normal {
              color: $primaryTextColor !important;
              div.ant-empty-description {
                color: $primaryTextColor !important;
              }
            }
          }
          &:hover {
            > td {
              background: $elementTableBackround !important;
            }
          }
        }

        .switch-wrap {
          .ant-form-item-control {
            min-height: 35px;
          }
        }

        .ant-form-item-control-input {
          min-height: 40px;
        }

        .table-action-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            color: $tableActionColor;
            padding: 6px 10px;
          }
        }

        .ant-table-row {
          .ant-table-cell-fix-left {
            background: $elementTableBackround !important;
          }
          .ant-table-cell-row-hover {
            background: $elementTableBackround !important;
          }
        }

        > tr {
          &:not(:last-child) {
            > td {
              border-color: $tableCellBorderColor !important;
            }
          }
        }
      }
    }
    .ant-empty-normal {
      color: $primaryTextColor !important;
    }
  }
}

.manage-options-modal {
  .ant-form-item {
    margin-bottom: 10px !important;
  }
  .ant-input {
    &:hover {
      border-color: #d9d9d9;
    }
    &:active {
      border-color: #d9d9d9;
    }
    &:focus {
      box-shadow: none;
      border-color: #d9d9d9;
    }
  }
  .add-option-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    .add-option-btn {
      background-color: $buttonBackground !important;
      border: none;
      border-radius: 6px;
      color: $white;
    }
  }
  .options-list {
    overflow: auto;
    max-height: 350px;
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    .ant-tag {
      display: flex;
      align-items: center;
      margin: 0 6px 6px 0 !important;
      justify-content: space-between;
      max-width: inherit;
      p {
        margin: 0 3px 0 0 !important;
      }
      .ant-tag-close-icon {
        color: $white;
      }
    }
  }
  .ant-modal-footer {
    padding: 15px 24px;
    button.ant-btn {
      border: none;
      box-shadow: none;
      color: #a5a5a5;
    }
    .ant-btn-default {
      background: $cancelButtonBackground;
      border-radius: 6px;
      font-weight: 600;
      color: $white !important;
    }
    .ant-btn-primary {
      background-color: $buttonBackground;
      color: $white !important;
      font-weight: 700;
      height: 35px;
      border: none;
      border-radius: 6px;
      min-width: 70px;
      &:hover {
        background-color: $buttonBackground;
        opacity: 0.8;
      }
    }
  }
}

.import-csv-wrapper {
  padding: 24px;
  .dropzone-container {
    color: $primaryTextColor;
    font-weight: 600;
    cursor: pointer;
    height: 12rem;
    .dropzone {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 10px;
      align-items: center;
      height: inherit;
      border: 2px dashed $lightGrey;
      border-radius: 6px;
      text-align: center;
    }
    aside {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      .file-name {
        margin-left: 5px;
      }
    }
  }
}

.import-drawer {
  .download-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
  }
}

.ant-spin-container {
  &::after {
    background: transparent !important;
  }
}

.ant-spin-nested-loading {
  > div {
    > .ant-spin {
      color: $buttonBackground !important;
    }
  }
}
.ant-spin-dot-item {
  background-color: $buttonBackground !important;
}
.ant-spin.ant-spin-show-text {
  .ant-spin-text {
    color: $buttonBackground;
  }
}

.user-form {
  .ant-table {
    .ant-form-item {
      margin-bottom: 0 !important;
    }
    .ant-table-content {
      background: $elementTableBackround !important;
      .ant-table-thead {
        tr {
          th {
            background: $elementTableBackround !important;
          }
        }
      }
      .ant-table-tbody {
        .ant-table-cell {
          padding: 10px !important;
        }
      }
      .ant-table-row:not(.section-form) {
        .ant-table-cell-row-hover {
          background: $elementTableBackround !important;
        }
      }

      .ant-checkbox-wrapper {
        &:hover {
          .ant-checkbox-inner {
            border-color: $buttonBackground;
          }
        }
      }
      .ant-checkbox {
        &:hover {
          .ant-checkbox-inner {
            border-color: $buttonBackground;
          }
        }
      }
      .ant-checkbox-input {
        &:focus {
          + {
            .ant-checkbox-inner {
              border-color: $buttonBackground;
            }
          }
        }
      }

      .ant-checkbox-wrapper {
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            border-color: $buttonBackground !important;
            background: $buttonBackground !important;
          }
        }
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.disabled-action-btn {
  color: #858585 !important;
  cursor: not-allowed;
}

.disabled-btn {
  background-color: #858585 !important;
  font-weight: 600 !important;
  &:hover,
  &:active {
    background-color: #858585 !important;
  }
}

.status-switch-wrap {
  width: 50px !important;
  button {
    width: 75px;
  }
  .ant-switch {
    background-color: #ff4d4f !important;
    background-image: none;
  }
  .ant-switch-checked {
    background-color: #48ca48 !important;
    .ant-switch-handle {
      &::before {
        background-color: $white;
      }
    }
  }
}

.disabled-switch {
  cursor: not-allowed;
  .ant-switch {
    pointer-events: none;
    opacity: 0.4;
  }
}

.small-box {
  border-radius: 0.5rem;
  box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
  display: block;
  margin-bottom: 20px;
  position: relative;
  background: #17a2b8;

  .content {
    display: flex;
    justify-content: space-between;
  }

  .inner {
    padding: 10px;
    color: white;
    font-weight: 600;
    h3 {
      color: white;
      font-size: 48px;
      font-weight: 700;
      margin: 0 0 10px;
      padding: 0;
      white-space: nowrap;
      z-index: 5;
    }
  }
  .icon {
    padding: 25px;
    color: rgba(0, 0, 0, 0.15);
    z-index: 0;
    display: flex;
    align-items: center;
    i {
      font-size: 70px;
      top: 20px;
    }
  }
  .small-box-footer {
    background-color: rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 600;
    display: block;
    padding: 3px 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    z-index: 10;
    cursor: pointer;
  }
}

.chart-card {
  margin-top: 24px;
  border-radius: 6px !important;
  padding: 14px;
  border-color: $tableCellBorderColor;
  background: $tableBackground;
}

.ant-picker-focused {
  border-color: $tagColor;
  box-shadow: none;
}

.ant-picker {
  &:hover,
  &:focus {
    border-color: $tagColor;
  }
  box-shadow: none;
}

.ant-picker-range {
  .ant-picker-active-bar {
    background: $tagColor;
  }
}

.ant-picker-dropdown {
  .ant-picker-content {
    .ant-picker-cell-in-view.ant-picker-cell-today {
      .ant-picker-cell-inner {
        &::before {
          border-color: $tagColor;
        }
      }
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-start {
      .ant-picker-cell-inner {
        background: $tagColor;
      }
    }
    .ant-picker-cell-in-view.ant-picker-cell-selected {
      .ant-picker-cell-inner {
        background: $tagColor;
      }
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-start {
      .ant-picker-cell-inner {
        background: $tagColor;
      }
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-end {
      .ant-picker-cell-inner {
        background: $tagColor;
      }
    }

    .ant-picker-cell-in-view.ant-picker-cell-in-range {
      &::before {
        background: $paginationBackground;
      }
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-start {
      &:not(.ant-picker-cell-range-start-single) {
        &::before {
          background: $paginationBackground;
        }
      }
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-in-range.ant-picker-cell-range-hover {
      &::before {
        background: $paginationBackground;
      }
    }

    .ant-picker-cell {
      &:hover {
        &:not(.ant-picker-cell-selected) {
          &:not(.ant-picker-cell-range-start) {
            &:not(.ant-picker-cell-range-end) {
              &:not(.ant-picker-cell-range-hover-start) {
                &:not(.ant-picker-cell-range-hover-end) {
                  .ant-picker-cell-inner {
                    background: $paginationBackground !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-end {
      &:not(.ant-picker-cell-range-end-single) {
        &::before {
          background: $paginationBackground;
        }
      }
    }
  }
  .ant-picker-footer {
    li {
      margin-right: 4px;
    }
  }
}

.transaction-logs-wrapper {
  margin-top: 5px;
  height: 50%;
  h1 {
    font-weight: 600;
    color: $primaryTextColor;
  }

  .ant-table-wrapper {
    border: 1px solid $tableCellBorderColor;
    border-radius: 10px;
    .ant-table.ant-table-bordered {
      > .ant-table-container {
        > .ant-table-content {
          > table {
            > thead {
              > tr {
                > th:last-child {
                  border-right: none;
                }
              }
            }
          }
        }
      }
    }
    .ant-table.ant-table-bordered {
      > .ant-table-container {
        > .ant-table-content {
          > table {
            > tbody {
              > tr {
                > td:last-child {
                  border-right: none;
                }
              }
            }
          }
        }
      }
    }

    .ant-table.ant-table-bordered {
      > .ant-table-container {
        border-left: none !important;
      }
    }

    .ant-table {
      .ant-table-content {
        table {
          border-color: $tableCellBorderColor !important;
          border-top: none;
        }

        .ant-table-thead {
          > tr {
            > th {
              padding: 10px 16px;
              font-weight: bold;
              color: $primaryTextColor;
              &:not(:last-child) {
                &:not(.ant-table-selection-column) {
                  &:not(.ant-table-row-expand-icon-cell) {
                    &:not([colspan]) {
                      &::before {
                        content: none !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .ant-table-tbody {
          > tr {
            &:last-child {
              > td {
                border-bottom: none;
              }
            }
            > td {
              border-color: $tableCellBorderColor !important;
            }
          }
        }
      }
    }
  }
}

.log-accordion {
  background-color: $tableBackground !important;
  border: none !important;
  .ant-collapse-item {
    margin-bottom: 16px;
    border: none;
    border-radius: 10px !important;
    background-color: $layoutBackground;

    .ant-collapse-header {
      color: $primaryTextColor;
      text-transform: capitalize;
    }
    .ant-collapse-content {
      background-color: $tableBackground;
      color: $primaryTextColor;
      border-color: $layoutBackground;
    }
    .ant-collapse-content-active {
      border-top: none !important;
      .ant-collapse-content-box {
        border-radius: 0 0 10px 10px;
        border: 1px solid $layoutBackground;
        border-top: none;
      }
    }
  }
}

.date-picker {
  background-color: $headerInputBackground;
  border: 1px solid $headerInputBackground;
  border-radius: 40px;
  padding: 5px 10px;
  height: 40px;
  .ant-picker-input {
    input {
      color: $primaryTextColor !important;
    }
  }
  .ant-picker-suffix {
    color: $primaryTextColor;
  }
}

.field-map-table-form {
  display: flex;
  justify-content: center;
  width: 100%;
  #fieldMapForm {
    width: 85%;
    .ant-table {
      .ant-select-selection-item {
        justify-content: center;
      }
    }
  }
}

.ant-select-dropdown {
  .select-option-placeholder {
    font-weight: 600 !important;
    padding: 5px 12px !important;
    color: $primaryTextColor !important;
  }
}

.icon-button {
  font-size: 18px;
  color: $buttonBackground;
  display: flex;
  align-items: center;
  padding: 6px 10px;
  &:hover,
  &:active,
  &:focus {
    color: $buttonBackground;
  }
}

.document-type-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-height: 350px;
  overflow-y: auto;
  gap: 4px;
  .ant-tag {
    display: flex;
    align-items: center;
    color: $white !important;
    gap: 8px;
    .ant-tag-close-icon {
      color: $white;
    }
    p {
      margin-bottom: 0 !important;
    }
  }
}

.standalone-switch-container {
  display: flex;
  position: relative;
  width: 350px;
  .standalone-switch-wrapper {
    position: absolute;
    display: flex;
    right: 52px;
    top: -15px;
    .switch-wrap {
      display: flex;
    }
    .ant-form-item-label {
      display: flex;
      width: 90px;
      label {
        font-size: 16px;
      }
    }
  }
}

.ant-modal-root {
  .ant-modal-content {
    .ant-modal-body {
      form {
        input {
          &::placeholder {
            color: #000 !important;
          }
        }

        .ant-form-item-label {
          label {
            color: #000 !important;
            font-weight: 600;
          }
        }
        .ant-input {
          background-color: #e6e6e6 !important;
          color: #000 !important;
          &:focus {
            box-shadow: none !important;
            background-color: #e6e6e6 !important;
          }
        }
        .ant-input-status-error {
          &:not(.ant-input-disabled) {
            &:not(.ant-input-borderless).ant-input {
              background: #e6e6e6 !important;
              border: 1px solid red !important;
            }
          }
        }
      }
    }
  }
}

.question-alert-pop-up {
  .ant-modal-title {
    font-weight: 600;
    color: #ef4d61 !important;
    .warning-header {
      display: flex;
      align-items: center;
      gap: 8px;
      p {
        margin-bottom: 0;
      }
    }
  }
  .partner-title {
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: 600;
  }
  .partner-list {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    .ant-tag {
      p {
        margin-bottom: 0;
      }
    }
  }
}
