.primary-text-color {
  color: $primaryTextColor !important;
}

.secondary-text-color {
  color: $secondaryTextColor !important;
}
.ant-btn-round {
  padding: 4px 10px !important;
}
.date-picker-user {
  .ant-picker-focused {
    box-shadow: none !important;
    outline: 0;
  }

  .ant-picker-focused,
  .ant-picker:hover {
    border-color: #d9d9d9 !important;
    border-right-width: 1px;
  }
  .date-picker-signup {
    width: 100%;
    border-radius: 6px;
    height: 40px !important;
  }
}
.range-filter {
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.refresh-btn {
  padding: 10px !important;
  background-color: white !important;
  width: 400px !important;
  margin-left: 12px;
}
.error-msg {
  color: rgb(220, 56, 56);
  font-size: 12px;
}
.fw-500 {
  font-weight: 500 !important;
}
.title-form {
  label {
    font-size: 13px !important;
  }
  // .ant-col {
  //   height: 40px;
  // }
  .ant-form-item-label {
    text-align: left !important;
  }
  .ant-select-selector {
    min-height: unset !important;
  }
  .ant-input {
    min-height: unset !important;
  }
}
.not-allowed-user {
  cursor: not-allowed !important;
  color: rgb(181, 178, 178) !important;
  pointer-events: none !important;
}
.blur-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(3px) !important;
}
.modal-container {
  z-index: 10000; /* Ensure this is above the blur overlay */
  position: relative;
}
.contact-info-popup {
  .ant-modal-close-x {
    display: none !important;
  }
  top: 50px !important;
  .ant-modal-body {
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 2px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: rgba(#7f93e2, 0.5);
    }
    max-height: 470px !important;
    overflow-y: auto !important;
    padding: 15px !important;
  }
}
.provider-heading {
  font-size: 18px;
  font-weight: 500;
  color: #1976d2;
}
.title-cal-form {
  .ant-alert-info {
    background-color: unset !important;
    border: unset !important;
  }
  // width: 40%;
  // margin: 0 auto !important;
  height: 100%;
  border: 1px solid #e2dfdf;
  border-radius: 10px;
  padding: 14px;
  .ant-input[disabled] {
    background: #f8f8f8 !important;
  }
}

.total {
  td {
    font-weight: 550 !important;
  }
}
.saving {
  td {
    font-weight: 550 !important;
    color: #1976d2 !important;
  }
}
.title-calc-header {
  .btns-right {
    display: flex;
    justify-content: end;
  }
  color: #1976d2;
  font-size: 18px;
  font-weight: 550;
}
.pricing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ant-layout {
  &.sider-collapsed {
    .site-layout {
      padding-left: 80px;
      .ant-layout-header {
        width: calc(100% - 80px) !important;
      }
      .form-footer {
        width: calc(100% - 80px) !important;
      }
    }
  }
  &.sider-full {
    .site-layout {
      padding-left: 230px;
      .ant-layout-header {
        width: calc(100% - 230px) !important;
      }
      .form-footer {
        width: calc(100% - 230px) !important;
      }
    }
  }
  .ant-layout-sider {
    background: $sidebarColor !important;
    box-shadow: 4px 4px 40px 0px #0000000f;
    position: fixed;
    top: 0;
    width: 100px;
    left: 0;
    height: 100%;
    z-index: 10;

    &.ant-layout-sider-collapsed {
      .ant-layout-sider-children {
        .sidebar-header {
          padding: 0;
          align-items: center;
          justify-content: center;
          .voxtur-logo {
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
          }
          .voxtur-title {
            display: none;
          }
        }

        .ant-menu {
          font-size: 16px !important;
          font-weight: 600;
          background: inherit !important;
          padding: 24px 8px;
          .ant-menu-item-icon {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          .ant-menu-submenu {
            &.ant-menu-submenu-selected {
              .ant-menu-submenu-title {
                background: $secondaryTextColor !important;
                border-radius: 10px;
                .ant-menu-title-content,
                .ant-menu-item-icon {
                  color: $sidebarColor !important;
                }
              }
            }
          }

          .ant-menu-item {
            transition: all 0.2s !important;
          }
        }
      }
    }

    .ant-layout-sider-children {
      .sidebar-header {
        height: 95px;
        padding: 20px 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        .voxtur-title {
          font-family: "DM Sans" !important;
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: 0em;
          color: $primaryTextColor;
          text-transform: uppercase;
          margin-bottom: 0;
          margin-left: 8px;
        }

        border-bottom: 1px solid $primaryBorderColor;
      }
      .main-menu {
        max-height: calc(100% - 95px);
        height: calc(100% - 95px);
        overflow-y: auto;

        @extend .custom-scrollbar;
      }

      .ant-menu {
        font-size: 16px !important;
        font-weight: 600;
        background: inherit !important;
        padding: 24px 8px;
        .ant-menu-submenu {
          transition: border-color 0s, background 0s;

          &.ant-menu-submenu-selected {
            .ant-menu-submenu-title {
              .ant-menu-title-content,
              .ant-menu-item-icon {
                color: $secondaryTextColor;
              }
            }
          }
          .ant-menu-submenu-title {
            transition: all 0s;
            border-radius: 10px;
            .ant-menu-title-content {
              font-size: 16px;
              font-weight: 400;
              line-height: 27px;
              letter-spacing: 0.016em;
            }
          }
          .ant-menu-sub {
            padding: 0;
          }
        }

        .ant-menu-item {
          color: $sidebarInactiveTextColor !important;
          padding: 16px !important;
          border-radius: 10px;
          display: flex;
          align-items: center;
          margin: 0 auto;
          margin-bottom: 4px;
          position: relative;
          .ant-menu-title-content {
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0.016em;
          }
          &.ant-menu-item-selected {
            background-color: $secondaryTextColor !important;
            color: $sidebarColor !important;
          }
        }
        .ant-menu-submenu {
          &.ant-menu-submenu-selected {
            .ant-menu-submenu-title {
              padding-left: 16px !important;
              .ant-menu-submenu-arrow {
                &::after,
                &::before {
                  background: $secondaryTextColor !important;
                }
              }
            }
          }
          .ant-menu-submenu-title {
            padding-left: 16px !important;
            .ant-menu-submenu-arrow {
              &::after,
              &::before {
                background: $sidebarInactiveTextColor !important;
              }
            }
            color: $sidebarInactiveTextColor !important;
          }
        }
        i {
          font-size: 14px !important;
          font-weight: 600 !important;
        }
      }
    }
  }

  &.site-layout {
    background: $layoutBackground;
    position: relative;
    // padding-left: 250px;
    min-height: 100vh;
    padding-top: 95px;
    transition: all 0.2s, background 0s;
    overflow-y: auto;

    .ant-layout-header {
      position: absolute !important;
      top: 0 !important;
      right: 0 !important;
      z-index: 9 !important;
      // width: calc(100% - 250px) !important;
      // width: 100%;
      background: $layoutBackground;
      height: 95px;
      display: flex;
      align-items: center;
      padding: 0;

      transition: all 0.2s, background 0s;

      .header-content {
        height: 65px;
        width: 100%;
        background: $sidebarColor;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 16px 24px !important;
        padding: 16px;
        border-radius: 6px;
        box-shadow: -25px -17px 30px 40px #00000008;

        .header-left {
          display: flex;
          align-items: center;
          .trigger {
            font-size: 24px;
            color: $primaryTextColor;
            font-weight: 500;
            margin-right: 16px;
          }
          .app-icon {
            font-size: 24px;
            background-color: transparent;
            margin-right: 8px;
            color: $secondaryTextColor;
            cursor: pointer;
          }
          .ant-divider-vertical {
            border-color: $primaryTextColor;
            height: 24px;
          }
          .ant-breadcrumb {
            margin-left: 8px;
            ol {
              li {
                .ant-breadcrumb-link,
                .ant-breadcrumb-separator {
                  font-size: 18px;
                  font-weight: 500;
                  line-height: 30px;
                  letter-spacing: 0.016em;
                  .link {
                    cursor: pointer;
                  }
                }
                &:only-child {
                  .ant-breadcrumb-link,
                  .ant-breadcrumb-separator {
                    color: $secondaryTextColor !important;
                  }
                }
                &:not(&:last-child) {
                  .ant-breadcrumb-link,
                  .ant-breadcrumb-separator {
                    color: $secondaryTextColor;
                  }
                }
              }
            }
          }
        }

        .header-right {
          display: flex;
          align-items: center;
          color: $primaryTextColor;
          .header-btn {
            background: $secondaryTextColor;
            color: $sidebarColor;
            height: 30px;
            width: 30px;
            min-width: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 16px;
            &.reset-table-filter {
              background: #faad14 !important;
            }
            i {
              font-size: 14px !important;
            }
          }
          .notification-bell {
            margin-right: 28px;
            cursor: pointer;
            .ant-avatar {
              background: transparent;
              color: $secondaryTextColor;
            }
          }
          .user-avatar {
            &.ant-avatar {
              width: 30px;
              height: 30px;
              color: $secondaryTextColor;
              background: #f8f8f8 !important;
              position: relative;
              i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 30px;
              }

              margin-right: 4px;
              cursor: pointer;
            }
          }
          .drop-down {
            cursor: pointer;
            .user-title {
              font-size: 18px;
              font-weight: 500;
              line-height: 27px;
              letter-spacing: 0.016em;
              text-align: left;
            }
          }
        }
      }
    }
    main.site-layout-background {
      max-height: calc(100% - 24px);
      height: calc(100% - 24px);
      margin: 0 24px 24px;
      background: $layoutBackground;
      transition: all 0.2s, background 0s !important;
      overflow: hidden;

      &.form-layout {
        margin-bottom: 90px;
      }

      .analytic-card {
        display: flex;
        align-items: center;
        justify-content: center;
        .container {
          position: relative;
          height: 190px;
          width: 100%;
          border-radius: 8px;
          // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 40px 0px;
          .ant-btn {
            position: absolute;
            bottom: 22px;
            left: 50%;
            transform: translateX(-50%);
            width: 146px;
            height: 35px;
            border-radius: 29px;
            border: none;
            color: $primaryTextColor;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0em;

            &:active,
            &:focus,
            &:hover {
              background-color: inherit;
              color: $primaryTextColor;
            }
          }
          .icon {
            width: 60px;
            height: 55px;
            border-radius: 16px;
            position: absolute;
            top: -25px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $primaryTextColor;
          }
          .data {
            position: absolute;
            top: 45px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .count {
              width: 100%;
              font-size: 26px;
              font-weight: 500;
              line-height: 35px;
              letter-spacing: 0em;
              text-align: center;
              color: $primaryTextColor;
              margin-bottom: 0px;
            }
            .title {
              width: 100%;
              font-size: 20px;
              font-weight: 500;
              line-height: 35px;
              letter-spacing: 0em;
              text-align: center;
              color: $primaryTextColor;
              margin-bottom: 0;
            }
          }
        }
      }
      .report-card {
        display: flex;
        align-items: center;
        justify-content: center;
        .container {
          position: relative;
          height: 225px;
          width: 100%;
          border-radius: 16px;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 40px 0px;
          .footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 0 0 16px 16px;
            padding: 4px 0;
            border: none;
            color: $primaryTextColor;
            font-size: 16px;
            font-weight: 500;
            line-height: 26px;
            letter-spacing: 0em;
            text-align: center;
          }
          .icon {
            width: 70px;
            height: 64px;
            border-radius: 16px;
            position: absolute;
            top: -30px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $primaryTextColor;
          }
          .data {
            position: absolute;
            top: 75px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .count {
              width: 100%;
              font-size: 32px;
              font-weight: 500;
              line-height: 40px;
              letter-spacing: 0em;
              text-align: center;
              color: $primaryTextColor;
              margin-bottom: 4px;
            }
            .title {
              width: 100%;
              font-size: 26px;
              font-weight: 500;
              line-height: 28px;
              letter-spacing: 0em;
              text-align: center;
              color: $primaryTextColor;
              margin-bottom: 0;
            }
          }
        }
      }

      .timeline-layout {
        background: transparent !important;
        height: 100%;
        padding: 24px 0;
        transition: all 0.2s, background 0s !important;
        .header {
          margin-bottom: 32px;
          .ant-card {
            border-radius: 6px;
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.03);
            height: 100%;
            &.ant-card-bordered {
              display: flex;
              align-items: center;
            }
            .ant-card-head {
              height: 100%;
              display: flex;
              align-items: center;
              border-color: $primaryBorderColor;
              color: $secondaryTextColor;
              .ant-card-head-wrapper {
                .ant-card-head-title {
                  padding: 0;
                  i {
                    margin-right: 8px;
                  }
                }
              }
            }
            .ant-card-body {
              padding: 18px 24px;
              color: $primaryTextColor;
              font-weight: 500;
              .detail {
                display: flex;
                align-items: flex-start;
                flex-direction: row;
                gap: 8px;
                p {
                  margin-bottom: 0;
                  font-size: 16px;
                }
                .title {
                  color: $secondaryTextColor;
                  white-space: pre;
                }
                .value {
                  color: $primaryTextColor;
                  word-break: break-word;
                }
              }
            }
          }
        }

        .timezone-tag {
          background: $tableRowColor !important;
          border-color: $secondaryTextColor !important;
          border-radius: 6px;
          color: $primaryTextColor !important;
          font-size: 14px !important;
          font-weight: 400;
          margin-left: auto;
          margin-right: 0;
          .timezone {
            margin-left: 4px;
            font-weight: 600;
          }
        }
        .container {
          width: 100%;
          margin-inline-end: auto;
          margin-inline-start: auto;
          padding-inline-end: 0.75rem;
          padding-inline-start: 0.75rem;
          padding-right: calc(16rem * 0.5);
          padding-left: calc(16rem * 0.5);
          margin-right: auto;
          margin-left: auto;
          .timeline {
            list-style-type: none;
            padding: 0;
            position: relative;
            &::before {
              content: "";
              position: absolute;
              inset-block-start: 15px;
              inset-block-end: 15px;
              width: 3px;
              background: rgba($secondaryTextColor, 0.5);
              inset-inline-start: 20%;
              margin-inline-start: -1px;
            }
            li {
              position: relative;
              min-height: 50px;
              padding: 15px 0;
              .activity-time {
                position: absolute;
                inset-inline-start: -7px;
                width: 18%;
                text-align: end;
                inset-block-start: 30px;
                .date,
                .time {
                  display: block;
                  font-weight: 500;
                }
                .date {
                  line-height: 16px;
                  font-size: 13px;
                  margin-block-end: 4px;
                  color: $primaryTextColor;
                }
                .time {
                  line-height: 24px;
                  font-size: 21px;
                  color: $primaryTextColor;
                }
              }
              .activity-icon {
                inset-inline-start: 15%;
                position: absolute;
                width: 10%;
                text-align: center;
                inset-block-start: 41px;
                .dot {
                  text-decoration: none;
                  width: 18px;
                  height: 18px;
                  display: inline-block;
                  border-radius: 50%;
                  background: $sidebarColor;
                  line-height: 10px;
                  color: $sidebarColor;
                  font-size: 14px;
                  border: 3px solid $secondaryTextColor;
                  transition: border-color 0.2s linear;
                }
              }
              .activity-body {
                &::before {
                  content: "";
                  display: block;
                  position: absolute;
                  border: 10px solid transparent;
                  border-inline-end-color: $sidebarColor;
                  inset-inline-start: -20px;
                  inset-block-start: 25px;
                }
                box-shadow: 0 8px 16px rgba(0, 0, 0, 0.03);
                margin-inline-start: 23%;
                margin-inline-end: 18%;
                background: $sidebarColor;
                position: relative;
                padding: 14px 20px;
                border-radius: 6px;
                display: flex;
                min-height: 70px;
                .details {
                  display: flex;
                  align-items: flex-start;
                  gap: 16px;
                  width: 100%;
                  .event-type {
                    margin: auto 0;
                    width: auto;
                    min-width: 92px;
                    line-height: 2.5rem;
                    font-size: 1rem;
                    .ant-tag {
                      background: $secondaryTextColor !important;
                      color: $sidebarColor !important;
                      font-size: 12px !important;
                    }
                  }
                  .event-details {
                    display: flex;
                    width: auto;
                    flex: 1 1;
                    position: relative;
                    p {
                      margin-bottom: 0;
                      color: $primaryTextColor;
                      margin-block-end: 0 !important;

                      &.event-name {
                        font-weight: 700 !important;
                      }
                    }
                    .change-log {
                      color: $primaryTextColor;
                      font-size: 13px !important;
                      color: rgba($primaryTextColor, 0.7);
                      .activity_anchor {
                        color: $secondaryTextColor !important;
                        font-weight: 500;
                      }
                    }
                    .time {
                      margin-left: auto;
                      font-size: 12px !important;
                      color: rgba($primaryTextColor, 0.6);
                    }
                  }
                }
              }
            }
          }
          &.empty-timeline {
            height: 100%;
            .empty-timeline-text {
              height: 100%;
              text-align: center;
              font-size: 20px;
              font-weight: 500;
              color: $secondaryTextColor;
              margin-top: -58px;
              display: flex;
              align-items: center;
              justify-content: center;
              p {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      .content-layout {
        height: 100%;
        transition: all 0.2s, background 0s !important;
        padding: 24px;
        &:not(.card-form-layout) {
          background: $sidebarColor;
          border-radius: 6px;
        }
        &.card-form-layout {
          padding: 0;
          .ant-card {
            border-radius: 6px;
            // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15) !important;
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.03);

            &.ant-card-bordered {
              margin: 4px 2px;
            }
            .ant-card-head {
              .ant-card-head-wrapper {
                .ant-card-head-title {
                  .title-with-icon {
                    display: flex;
                    align-items: center;
                    color: $secondaryTextColor;
                    gap: 8px;
                    font-weight: 600;
                    p {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
          }
        }
        .form-item {
          label {
            background: rgba($tableRowColor, 1);
            width: 100%;
            height: 100%;
            display: block;
            padding: 12px 16px;
            font-size: 14px;
            color: $primaryTextColor;
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
          }
          p {
            padding: 12px 16px;
            font-size: 14px;
            color: $sidebarInactiveTextColor;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
          }
          .no-image {
            margin: 8px !important;
            font-size: 16px;
            text-align: center;
            height: 200px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: 1px solid $primaryBorderColor;
            border-radius: 6px;
            p {
              text-align: center;
              &.no-logo {
                font-size: 16px;
                font-weight: 500;
              }
              font-size: 13px;
            }
          }
          .image-wrapper {
            padding: 12px 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            .ant-image {
              height: 200px;
              width: 200px;
            }
          }
        }
        .form-title-header {
          .form-title-row {
            display: flex;
            align-items: center;
            gap: 8px;
            color: $secondaryTextColor !important;
            i {
              font-size: 18px;
            }
            h1 {
              margin-bottom: 0;
            }
            .form-title {
              color: $secondaryTextColor !important;
            }
          }
        }

        .form-title-row {
          margin-bottom: 16px;
          .form-title {
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: 0em;
            color: $primaryTextColor;
          }
        }
        .event-list,
        .product-list {
          display: flex;
          flex-wrap: wrap;
          row-gap: 8px;
        }
        .listing-header {
          margin-bottom: 16px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          padding-bottom: 16px;
          gap: 16px;
          border-bottom: 1px solid $tableRowColor;
          .ant-divider {
            height: 75%;
            border-color: $primaryTextColor !important;
            margin: 0;
          }

          .filter-btn {
            background: transparent;
            box-shadow: none;
            color: $primaryTextColor;
            border: none;
            font-size: 14px;
            font-weight: 400;
            line-height: 27px;
            letter-spacing: 0.016em;
            text-align: left;
            padding: 19px 12px !important;
            display: flex;
            align-items: center;
            &.active {
              color: $sidebarColor;
            }
            &::after {
              box-shadow: none !important;
            }
          }

          .filter-input {
            height: 100%;
            border: none;
            width: 240px;
            max-width: 240px;
            height: 40px;
            max-height: 40px;
            color: $primaryTextColor;
            border: 1px solid $inputBorderColor;
            border-radius: 6px;

            &.ant-select {
              display: flex;
              align-items: center;
              .ant-select-selector {
                color: $primaryTextColor;
                border: none;
                border-radius: 6px;

                box-shadow: none;
                height: 100%;
                display: flex;
                align-items: center;
                padding: 4px 11px !important;
                .ant-select-selection-placeholder {
                  color: rgba($primaryTextColor, 0.6);
                }
              }
              input {
                height: 100%;
              }
            }
            .ant-select-suffix,
            .ant-select-clear,
            .ant-select-arrow-loading {
              color: $primaryTextColor !important;
            }
            &::placeholder {
              color: rgba($primaryTextColor, 0.6);
            }
            input {
              &::placeholder {
                color: rgba($primaryTextColor, 0.6);
              }
            }
            &:active,
            &:hover {
              border-color: $inputBorderColor !important;
            }
            &.ant-input-affix-wrapper-focused,
            &.ant-picker-focused {
              box-shadow: none !important;
            }
            &:focus {
              box-shadow: none !important;
            }
            .ant-input-suffix {
              color: $primaryTextColor;
            }
            &.ant-picker {
              .ant-picker-range-separator {
                .ant-picker-separator {
                  .anticon {
                    color: $primaryTextColor;
                  }
                }
              }
              .ant-picker-suffix {
                color: $primaryTextColor;
              }
              .ant-picker-active-bar {
                background: $secondaryTextColor !important;
              }
              .ant-picker-clear {
                .anticon {
                  color: $primaryTextColor;
                }
              }
            }
          }

          .header-left {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 12px;
            .status-filter {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 12px;
              padding: 0px 4px;
              border: 1px solid $secondaryTextColor;
              border-radius: 6px;
              $highlight-width: auto;
              $highlight-x-pos: 41px;
              .controls {
                display: inline-flex;
                justify-content: space-between;
                background: $white;
                border-radius: 10px;
                max-width: 500px;
                overflow: hidden;
                position: relative;
                &::before {
                  transition: transform 0.3s ease, width 0.3s ease;
                  content: "";
                  background: $secondaryTextColor;
                  border-radius: 6px;
                  width: var(--highlight-width);
                  transform: translateX(var(--highlight-x-pos));
                  position: absolute;
                  top: 4px;
                  bottom: 4px;
                  left: 0;
                  z-index: 0;
                }
              }
            }
          }

          .header-right {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 12px;
          }
          .advance-filter-row {
            width: 100%;
            .ant-collapse {
              width: 100%;
              .ant-collapse-item {
                .ant-collapse-header {
                  display: none;
                }
                .ant-collapse-content {
                  border: 1px solid $inputBorderColor;
                  border-radius: 6px;
                  .ant-collapse-content-box {
                    padding: 12px 16px 16px;
                    .filter-input {
                      width: 100% !important;
                      max-width: 100% !important;
                    }
                    .input-wrapper {
                      label {
                        font-size: 12px;
                        color: $primaryTextColor;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .ant-tabs-extra-content,
        .section-extras {
          .add-btn {
            height: 32px !important;
          }
        }
        form {
          .add-btn {
            height: 32px !important;
            font-size: 14px !important;
          }
          .section-header {
            margin-top: 8px;
          }
        }

        .question-section-form-wrapper {
          .header-actions {
            display: flex;
            justify-content: flex-end;
          }
          .empty-section {
            text-align: center;
            margin-top: 48px;
            background: transparent;
            h2 {
              color: $primaryTextColor;
              font-weight: 500;
              margin-top: 8px;
            }
            h3,
            h4 {
              color: $primaryTextColor;
              font-weight: 500;
            }
          }
          .section-form {
            .section-extras {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              gap: 8px;
              margin-bottom: 24px;
            }
            .elements-table-wrapper {
              display: flex;
              justify-content: center;
            }
          }
        }
        .report-wrapper {
          padding-top: 48px;
        }
        .field-map-table-form {
          .header {
            display: flex;
            gap: 24px;
            .title {
              .partner-name {
                font-size: 20px;
                font-weight: 500;
                line-height: 30px;
                letter-spacing: 0.016em;
                color: $secondaryTextColor;
                span {
                  color: $primaryTextColor;
                  font-weight: 500;
                }
              }
            }
          }
          .ant-table {
            .ant-select-selection-item {
              justify-content: center;
            }
          }
        }
      }
      .transaction-iframe {
        position: relative;
        height: 100%;
        .close-icon {
          position: absolute;
          right: 12px;
          z-index: 1;
          top: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          .ant-btn {
            font-size: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.dark-btn {
              color: $white;
            }
          }
        }
        .ant-spin-nested-loading {
          height: 100%;

          .ant-spin-container {
            height: 100%;
            &::after {
              display: none;
            }
            iframe {
              background: $sidebarColor;
              width: 100%;
              border-radius: 6px;
              height: 100%;
              border: 0px;
            }
          }
        }
      }
      .dashboard-wrapper {
        transition: all 0.2s, background 0s !important;
        margin-top: 24px;
        .section {
          background-color: $sidebarColor;
          padding: 24px;
          border-radius: 6px;
          // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

          .header-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;
            p {
              margin-bottom: 0;
            }
            .section-header {
              display: flex;
              align-items: center;
              gap: 8px;
              i {
                font-size: 24px;
                color: $secondaryTextColor;
              }
              .section-title {
                color: $secondaryTextColor;
                font-size: 20px;
                font-weight: 500;
                line-height: 30px;
              }
            }
          }
        }
        .ant-table {
          .ant-table-content {
            @extend .custom-scrollbar;
          }
        }
      }
      .admin-dashboard-wrapper {
        transition: all 0.2s, background 0s !important;
        padding-top: 16px;
        .transaction-logs-wrapper {
          background-color: $sidebarColor;
          border: 1px solid $primaryBorderColor;
          border-radius: 6px;
          padding: 24px;
          height: 100%;
          .title-wrapper {
            display: flex;
            align-items: center;
            gap: 8px;
            i {
              color: $secondaryTextColor;
              font-size: 24px;
            }
          }
          .title {
            font-size: 20px;
            font-weight: 500;
            line-height: 36px;
            letter-spacing: 0em;
            color: $secondaryTextColor;
            margin-bottom: 0;
          }
          .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .graph-legend {
              display: flex;
              align-items: center;
              gap: 12px;
              .legend-block {
                width: 50px;
                height: 15px;
                background: $secondaryTextColor;
              }
              .label {
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0em;
                margin-bottom: 0 !important;
                color: $primaryTextColor;
              }
            }
            .ant-picker {
              padding: 8px 20px;
              .ant-picker-active-bar {
                width: 85px !important;
                margin-left: 20px !important;
              }
              .ant-picker-clear {
                right: 20px !important;
              }
            }
            margin-bottom: 24px;
          }
          canvas {
            min-height: 250px;
            height: 100% !important;
          }
          .ant-table {
            .ant-table-content {
              @extend .custom-scrollbar;
            }
          }
        }
      }
    }
  }
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba($scrollBarThumbColor, 0.5);
  }
}

&::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
&::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: transparent;
}
&::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba($scrollBarThumbColor, 0.5);
}
.header-toggle {
  form .ant-form-item .ant-form-item-label label {
    margin-top: 2.5px;
    color: $secondaryTextColor !important;
    font-weight: 500 !important;
  }
  form
    .ant-form-item
    .ant-form-item-control
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    .ant-select
    .ant-select-selector {
    min-height: unset !important;
  }
}
.nav-drawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        .ant-drawer-body {
          padding: 0;
          .sidebar-header {
            padding: 16px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            .voxtur-title {
              font-family: "DM Sans" !important;
              font-size: 20px;
              font-weight: 700;
              line-height: 26px;
              letter-spacing: 0em;
              color: $primaryTextColor;
              text-transform: uppercase;
              margin-bottom: 0;
              margin-left: 8px;
            }

            border-bottom: 1px solid $primaryBorderColor;
          }
          .main-menu {
            max-height: calc(100% - 86px);
            height: calc(100% - 86px);

            overflow-y: auto;

            @extend .custom-scrollbar;
          }

          .ant-menu {
            font-size: 16px !important;
            font-weight: 600;
            background: inherit !important;
            padding: 24px 16px;
            .ant-menu-submenu {
              transition: border-color 0s, background 0s;

              &.ant-menu-submenu-selected {
                .ant-menu-submenu-title {
                  .ant-menu-title-content,
                  .ant-menu-item-icon {
                    color: $secondaryTextColor;
                  }
                }
              }
              .ant-menu-submenu-title {
                transition: all 0s;
                border-radius: 10px;
                .ant-menu-title-content {
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 27px;
                  letter-spacing: 0.016em;
                }
              }
              .ant-menu-sub {
                padding: 0;
              }
            }

            .ant-menu-item {
              color: $sidebarInactiveTextColor !important;
              padding: 16px !important;
              border-radius: 10px;
              display: flex;
              align-items: center;
              margin: 0 auto;
              margin-bottom: 4px;
              position: relative;
              .ant-menu-title-content {
                font-size: 16px;
                font-weight: 400;
                line-height: 26px;
                letter-spacing: 0.016em;
              }
              &.ant-menu-item-selected {
                background-color: $secondaryTextColor !important;
                color: $sidebarColor !important;
              }
            }
            .ant-menu-submenu {
              &.ant-menu-submenu-selected {
                .ant-menu-submenu-title {
                  padding-left: 16px !important;
                  .ant-menu-submenu-arrow {
                    &::after,
                    &::before {
                      background: $secondaryTextColor !important;
                    }
                  }
                }
              }
              .ant-menu-submenu-title {
                padding-left: 16px !important;
                .ant-menu-submenu-arrow {
                  &::after,
                  &::before {
                    background: $sidebarInactiveTextColor !important;
                  }
                }
                color: $sidebarInactiveTextColor !important;
              }
            }
            i {
              font-size: 14px !important;
              font-weight: 600 !important;
            }
          }
        }
      }
    }
  }
}
.cursor-pointer {
  cursor: pointer !important;
}
.timeline-ack {
  display: flex;
  justify-content: end;
  .timezone-tag {
    margin-left: 10px !important;
  }
}
.ant-table-wrapper {
  &.row-cursor-pointer {
    .ant-table {
      .ant-table-container {
        .ant-table-tbody {
          .ant-table-row {
            cursor: pointer;
          }
        }
      }
    }
  }
  &:not(
      .editable-table,
      .access-control-table,
      .event-table,
      .log-request-info,
      .best-x-tables
    ) {
    .ant-table {
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1) !important;
      .ant-table-container {
        // .ant-table-content {
        @extend .custom-scrollbar;
        .ant-table-thead {
          .ant-table-cell {
            .ant-table-filter-column {
              justify-content: flex-start;
              .ant-table-column-title {
                flex: inherit;
                .ant-table-column-sorters {
                  .ant-table-column-sorter {
                    margin-left: 8px;
                    font-size: 6px;
                    .ant-table-column-sorter-inner {
                      .ant-table-column-sorter-up {
                        &.active {
                          color: $secondaryTextColor;
                        }
                      }
                    }
                  }
                }
              }
              .ant-table-filter-trigger {
                margin: -4px -4px -4px 8px !important;
                .anticon-filter {
                  height: 100%;
                  display: flex;
                  align-items: center;
                }
              }
            }
            &.active-filter {
              .ant-table-filter-column {
                .ant-table-filter-trigger {
                  .anticon-filter {
                    color: $secondaryTextColor;
                  }
                }
              }
            }
            background: $tableRowColor;
            color: $primaryTextColor;
            font-size: $tableCellFontSize;
            font-weight: 600;
            // text-transform: uppercase;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            padding: 6px 16px;
            &::before {
              display: none;
            }
          }
        }
        .ant-table-body {
          max-height: calc(100vh - 280px);
          overflow: auto !important;
          @extend .custom-scrollbar;
        }
        .ant-table-tbody {
          max-height: calc(100vh - 280px);
          overflow: auto !important;
          @extend .custom-scrollbar;

          tr {
            &:nth-child(odd) {
              &:hover {
                .ant-table-cell {
                  // background-color: $sidebarColor;
                }
              }
            }
            &:hover {
              box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1) !important;
            }
          }
          .ant-table-row {
            &:nth-child(even) {
              .ant-table-cell {
                background-color: $sidebarColor !important;
              }
            }
            &:nth-child(odd) {
              .ant-table-cell {
                background-color: rgb(246, 249, 252) !important;
              }
            }
            &.even-row {
              .ant-table-cell {
                background-color: transparent !important;
              }
            }
            &.odd-row {
              .ant-table-cell {
                background-color: rgba($tableRowColor, 0.5) !important;
              }
            }
            &.error-row {
              .ant-table-cell {
                &:not(.ant-table-row-expand-icon-cell) {
                  color: #dc3838 !important;
                }
              }
            }
            &.highlighted-color-row {
              .ant-table-cell {
                &:not(.ant-table-row-expand-icon-cell) {
                  color: #1976d2 !important;
                }
              }
            }
            &.highlighted-row {
              .ant-table-cell {
                background-color: #daecfe !important;
              }
            }
            .ant-table-cell {
              color: $primaryTextColor;
              font-size: $tableCellFontSize;
              font-weight: 400;
              line-height: 21px;
              letter-spacing: 0em;
              text-align: left;
              padding: 6px 16px;
              .ant-tag {
                font-size: 12px !important;
                padding: 2px 6px !important;
                margin-right: 0 !important;
                &.centered {
                  margin: 0 auto !important;
                }
              }
              .switch-wrap {
                .ant-switch {
                  height: 18px;
                  line-height: 18px;
                  min-width: 38px;
                  font-size: $tableCellFontSize;
                  &.ant-switch-checked {
                    .ant-switch-handle {
                      left: calc(100% - 16px);
                    }
                  }
                  .ant-switch-handle {
                    height: 14px;
                    width: 14px;
                  }
                }
              }

              .table-action-wrap {
                display: flex;
                align-items: center;
                justify-content: center;
                button {
                  height: auto;
                  color: $secondaryTextColor;
                  padding: 2px 10px;
                  font-size: $tableCellFontSize;
                  &:disabled {
                    color: rgba($primaryTextColor, 0.6) !important;
                  }
                }
              }
              .link-table-cell {
                color: $secondaryTextColor;
                font-weight: 500;
                cursor: pointer;
              }
              .empty-cell {
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
        // }
      }
    }
  }
  &.transaction-list-table {
    .ant-table {
      .ant-table-container {
        .ant-table-tbody {
          .ant-table-row {
            &:nth-child(odd) {
              .ant-table-cell {
                background-color: rgb(246, 249, 252) !important;
              }
            }
            &:nth-child(even) {
              .ant-table-cell {
                background-color: $sidebarColor !important;
              }
            }
            &.highlighted-row {
              .ant-table-cell {
                background-color: #daecfe !important;
              }
            }
          }
        }
      }
    }
  }
  .ant-pagination {
    justify-content: center !important;
    margin-top: 24px;
    .ant-pagination-total-text {
      color: $primaryTextColor;
      font-weight: 500;
      position: absolute;
      left: 6px;
    }
  }
}
.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.client-name {
  &:hover {
    color: #1976d2;
  }
  color: white;
}
.editable-table {
  .ant-table-container {
    border: none !important;
  }
  .ant-table {
    overflow: hidden;
    .ant-input,
    .ant-select-selector {
      background: $sidebarColor !important;
    }

    .ant-form-item-control {
      .ant-form-item-control-input {
        min-height: 40px;

        .ant-form-item-control-input-content {
          .ant-select {
            &.ant-select-focused {
              &:not(.ant-select-disabled).ant-select {
                &:not(.ant-select-customize-input) {
                  .ant-select-selector {
                    box-shadow: none !important;
                  }
                }
              }
            }
            &:not(.ant-select-status-error) {
              .ant-select-selector {
                border-color: transparent !important;
              }
            }
            .ant-select-selector {
              border-radius: 0;
              .ant-select-selection-search {
                max-height: 40px;
                display: flex;
                align-items: center;
              }
              .ant-select-selection-item {
                max-height: 40px;
                display: flex;
                align-items: center;
              }
            }
          }
          .ant-input {
            &:not(.ant-input-status-error) {
              border: 1px solid transparent !important;
            }
            border-radius: 0;
            &:focus {
              box-shadow: none !important;
            }
          }
        }
      }
    }
    background: $sidebarColor !important;
    table {
      border: none !important;
    }
    background: $sidebarColor !important;
    scrollbar-width: thin;
    color: $primaryTextColor;
    border: 2px solid $elementTableBorderColor;
    // border-bottom: none;
    border-radius: 10px;
    .ant-table-body {
      max-height: calc(100vh - 350px);
      overflow: auto !important;
      @extend .custom-scrollbar;
    }
    @extend .custom-scrollbar;

    .ant-form-item-explain {
      min-height: 1px;
      color: $primaryTextColor;
      .ant-form-item-explain-success,
      .ant-form-item-explain-error {
        font-size: 12px;
      }
    }

    .ant-table-thead {
      > tr {
        > th {
          &.fixed-column {
            left: 0 !important;
          }
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          // text-transform: uppercase;
          text-align: left;
          padding: 8px;
          background: $tableRowColor !important;
          border-color: $elementTableBorderColor !important;
          border-width: 2px;
          border-right: 2px solid $elementTableBorderColor !important;
          &:last-child {
            border-right-color: transparent !important;
          }
          color: $primaryTextColor;
          &:not(:last-child) {
            &:not(.ant-table-selection-column) {
              &:not(.ant-table-row-expand-icon-cell) {
                &:not([colspan]) {
                  &::before {
                    content: none !important;
                  }
                }
              }
            }
          }
          &::before {
            display: none;
          }
        }
      }
    }

    .ant-table-tbody {
      cursor: default;
      .empty-table-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
      }
      .drag-handle {
        color: $primaryTextColor;
        cursor: grab;
      }
      .tooltip-wrapper {
        height: 40px;
      }
      .ant-form-item {
        margin-bottom: 0 !important;
        .ant-form-item-control {
          height: 40px !important;
        }

        .ant-form-item-explain {
          .ant-form-item-explain-error {
            display: none;
          }
        }

        .ant-input,
        .ant-select-selector,
        .ant-picker {
          border-radius: 0 !important;
          font-size: 14px !important;
          height: 40px !important;
          align-items: center;
          justify-content: center;
        }
        .ant-input-status-error {
          &:not(.ant-input-disabled) {
            &:not(.ant-input-borderless).ant-input {
              background: transparent !important;
              border: 1px solid #ff4d4f !important;
            }
          }
        }
      }

      tr.active-table-row {
        background-color: $secondaryTextColor !important;
        .ant-input,
        .ant-select-selector {
          background-color: $secondaryTextColor !important;
          color: $sidebarColor;
          &::placeholder {
            color: $sidebarColor;
          }
        }
        .ant-select-single.ant-select-open .ant-select-selection-item {
          color: $sidebarColor !important;
        }
        .ant-select-selection-placeholder {
          color: $sidebarColor !important;
        }
        .ant-select-arrow {
          color: $sidebarColor !important;
        }
        .empty-table-cell {
          color: $sidebarColor;
        }
        .ant-table-cell-fix-left {
          background-color: $secondaryTextColor !important;
        }
        .ant-table-cell-row-hover {
          background: $secondaryTextColor !important;
        }
        .ant-table-cell {
          color: $sidebarColor;
        }
        .ant-form-item-control-input {
          background: $secondaryTextColor;
          color: $sidebarColor;
          .table-action-wrap {
            button {
              color: $sidebarColor !important;
            }
          }
        }
        .ant-select-clear {
          background: $secondaryTextColor;
          color: $sidebarColor;
        }
        .drag-handle {
          color: $sidebarColor !important;
        }
      }

      > tr.ant-table-row {
        &:hover {
          > td {
            background: transparent !important;
          }
        }
      }
      > tr {
        > td.ant-table-cell-row-hover {
          background: transparent !important;
        }
      }

      tr {
        .ant-table-cell {
          &:not(&:last-child) {
            border-right: 2px solid $elementTableBorderColor;
          }
          &:last-child {
            border-right-color: transparent !important;
          }
        }
      }

      td.ant-table-cell {
        padding: 0 !important;
        &.fixed-column {
          left: 0 !important;
        }
        .switch-wrap {
          display: flex;
          justify-content: center;
          align-items: center;
          .ant-col {
            justify-content: center;
          }
        }
      }
      > tr.ant-table-placeholder {
        > td.ant-table-cell {
          > div.ant-empty-normal {
            color: $primaryTextColor !important;
            div.ant-empty-description {
              color: $primaryTextColor !important;
            }
          }
        }
        &:hover {
          > td {
            background: $sidebarColor !important;
          }
        }
      }

      .switch-wrap {
        .ant-form-item-control {
          min-height: 35px;
        }
      }

      .ant-form-item-control-input {
        min-height: 40px;
      }

      .table-action-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          color: $secondaryTextColor;
          padding: 6px 10px;
        }
      }

      .ant-table-row {
        .ant-table-cell-fix-left {
          background: $sidebarColor !important;
        }
        .ant-table-cell-row-hover {
          background: $sidebarColor !important;
        }
      }

      > tr {
        &:not(:last-child) {
          > td {
            border-color: $elementTableBorderColor !important;
            border-width: 2px !important;
          }
        }
      }
    }
    .ant-empty-normal {
      color: $primaryTextColor !important;
    }
    &.ant-table.ant-table-bordered {
      > .ant-table-container {
        > .ant-table-content {
          > table {
            > tbody {
              > tr {
                > td {
                  &:not(&:last-child) {
                    border-right: 2px solid $elementTableBorderColor !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.ant-table-ping-left .ant-table-cell-fix-left-first:after,
.ant-table-ping-left .ant-table-cell-fix-left-last:after,
.ant-table-ping-left:not(.ant-table-has-fix-left)
  > .ant-table-container:before {
  box-shadow: none !important;
  border-left : 1px solid #ebebeb !important;
}
.best-x-tables-border-none {
  .ant-table-tbody > tr > td {
    border-bottom: none !important;
  }
}
.best-x-tables {
  .ant-table-expanded-row-fixed {
    padding: 0px !important;
  }
  .ant-tag.active {
    border-color: transparent !important;
  }
  .ant-tag.error {
    border-color: transparent !important;
  }
  .ant-tag {
    border-color: transparent !important;
  }
  .ant-form-item {
    margin: 0px !important;
  }
  .ant-table {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1) !important;
    .ant-table-container {
      // .ant-table-content {
      @extend .custom-scrollbar;
      .ant-table-thead {
        .ant-table-cell {
          .ant-table-filter-column {
            justify-content: flex-start;
            .ant-table-column-title {
              flex: inherit;
              .ant-table-column-sorters {
                .ant-table-column-sorter {
                  margin-left: 8px;
                  font-size: 6px;
                  .ant-table-column-sorter-inner {
                    .ant-table-column-sorter-up {
                      &.active {
                        color: $secondaryTextColor;
                      }
                    }
                  }
                }
              }
            }
            .ant-table-filter-trigger {
              margin: -4px -4px -4px 8px !important;
              .anticon-filter {
                height: 100%;
                display: flex;
                align-items: center;
              }
            }
          }
          &.active-filter {
            .ant-table-filter-column {
              .ant-table-filter-trigger {
                .anticon-filter {
                  color: $secondaryTextColor;
                }
              }
            }
          }
          background: $tableRowColor;
          color: $primaryTextColor;
          font-size: $tableCellFontSize;
          font-weight: 600;
          // text-transform: uppercase;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          padding: 6px 16px;
          &::before {
            display: none;
          }
        }
      }
      .ant-table-body {
        max-height: calc(100vh - 280px);
        overflow: auto !important;
        @extend .custom-scrollbar;
      }
      .ant-table-tbody {
        max-height: calc(100vh - 280px);
        overflow: auto !important;
        @extend .custom-scrollbar;

        tr {
          &:nth-child(odd) {
            &:hover {
              .ant-table-cell {
                // background-color: $sidebarColor;
              }
            }
          }
          // &:hover {
          //   box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1) !important;
          // }
        }
        .ant-table-row {
          &:nth-child(even) {
            .ant-table-cell {
              // background-color: $sidebarColor !important;
            }
          }
          &:nth-child(odd) {
            .ant-table-cell {
              // background-color: rgb(246, 249, 252) !important;
            }
          }
          &.even-row {
            .ant-table-cell {
              background-color: transparent !important;
            }
          }
          &.odd-row {
            .ant-table-cell {
              background-color: rgba($tableRowColor, 0.5) !important;
            }
          }
          &.error-row {
            .ant-table-cell {
              &:not(.ant-table-row-expand-icon-cell) {
                color: #dc3838 !important;
              }
            }
          }
          &.highlighted-row {
            .ant-table-cell {
              background-color: #daecfe !important;
            }
          }
          &.spammed-row {
            .ant-table-cell {
              background-color: rgb(177 177 177 / 15%) !important;
              color: grey;
            }
          }
          .ant-table-cell {
            color: $primaryTextColor;
            font-size: $tableCellFontSize;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
            padding: 6px 16px;
            .ant-tag {
              font-size: 12px !important;
              padding: 2px 6px !important;
              margin-right: 0 !important;
              &.centered {
                margin: 0 auto !important;
              }
            }
            .switch-wrap {
              .ant-switch {
                height: 18px;
                line-height: 18px;
                min-width: 38px;
                font-size: $tableCellFontSize;
                &.ant-switch-checked {
                  .ant-switch-handle {
                    left: calc(100% - 16px);
                  }
                }
                .ant-switch-handle {
                  height: 14px;
                  width: 14px;
                }
              }
            }

            .table-action-wrap {
              display: flex;
              align-items: center;
              justify-content: center;
              button {
                height: auto;
                color: $secondaryTextColor;
                padding: 2px 10px;
                font-size: $tableCellFontSize;
                &:disabled {
                  color: rgba($primaryTextColor, 0.6) !important;
                }
              }
            }
            .link-table-cell {
              color: $secondaryTextColor;
              font-weight: 500;
              cursor: pointer;
            }
            .empty-cell {
              height: 32px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
      // }
    }
  }
}
.switch-wrap {
  .ant-switch:focus {
    box-shadow: none !important;
  }
  .ant-switch {
    background-image: none;
  }
  .ant-form-item-control {
    align-items: center;
    flex-direction: row;
    display: flex;
  }
  .ant-switch-checked {
    .ant-switch-handle {
      &::before {
        background-color: $white;
      }
    }
  }
}

.ant-switch {
  background-image: none;
}
.ant-switch-checked {
  background-color: $secondaryTextColor !important;
  .ant-switch-handle {
    &::before {
      background-color: $white;
    }
  }
}

.ant-pagination {
  .ant-pagination-item {
    &:hover {
      a {
        color: $secondaryTextColor;
      }

      border-color: $secondaryTextColor;
    }
    &.ant-pagination-item-active {
      a {
        color: $secondaryTextColor;
      }
      border-color: $secondaryTextColor !important;
    }
  }
  .ant-pagination-item-link {
    &:not(&:disabled) {
      &:hover {
        color: $secondaryTextColor;
        border-color: $secondaryTextColor;
      }
    }
  }
  .ant-pagination-options {
    .ant-select {
      &.ant-select-focused {
        .ant-select-selector {
          box-shadow: none;
          border-color: $secondaryTextColor;
        }
      }
      .ant-select-selector {
        &:hover {
          border-color: $secondaryTextColor;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.ant-spin {
  .ant-spin-dot {
    .ant-spin-dot-item {
      background-color: $secondaryTextColor;
    }
  }
  .ant-spin-text {
    color: $secondaryTextColor;
  }
}

.ant-picker-dropdown {
  .ant-picker-range-wrapper {
    .ant-picker-panel-container {
      .ant-picker-footer {
        .ant-picker-ranges {
          .ant-picker-preset {
            .ant-tag {
              background: transparent !important;
              border-color: $secondaryTextColor;
              color: $secondaryTextColor;
            }
          }
        }
      }
    }
  }
}
.log-documents{
  .ant-btn-primary{
    display: none !important;
  }
}
.doc-main {
  border: 1px solid #d2cfcf;
  padding: 6px;
  cursor: pointer !important;
  border-radius: 5px;
}
.doc-link {
  color: #005fd4 !important;
  cursor: pointer;
  margin-top: 4px;
}
.doc-link-modal {
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
}
.doc-desc-modal {
  font-size: 12px;
  color: grey;
   margin-top: 4px;
}
.doc-modal-icon{
  display: flex;
  align-items: center;
}
.add-row-btn {
  width: 30px;
  height: 30px;
  border: 1px solid $secondaryTextColor;
  border-radius: 50%;
  background-color: transparent;
  position: relative;
  &:active,
  &:hover,
  &:focus {
    border: 1px solid $secondaryTextColor;
  }
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $secondaryTextColor;
  }
}

.delete-row-btn {
  width: 30px;
  height: 30px;
  border: 1px solid #f24e44;
  border-radius: 50%;
  background-color: transparent;
  position: relative;
  &:active,
  &:hover,
  &:focus {
    border: 1px solid #f24e44;
  }
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #f24e44;
  }
}

form {
  .ant-form-item {
    margin-bottom: 8px;
    .ant-form-item-label {
      padding-bottom: 5px;
      label {
        color: $primaryTextColor !important;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-align: left;
        &::before {
          position: absolute;
          right: -12px;
          top: 0px;
          font-size: 12px;
        }
      }
    }
    .ant-form-item-control {
      background: $sidebarColor;
      .ant-form-item-control-input {
        min-height: 40px;

        .ant-form-item-control-input-content {
          .ant-input-password {
            min-height: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            border-radius: 6px;
            &:not(.ant-input-affix-wrapper-status-error) {
              border: 1px solid $inputBorderColor !important;
            }

            .ant-input {
              border: none !important;
            }
            &.ant-input-affix-wrapper-focused {
              box-shadow: none !important;
            }
            &.ant-input-affix-wrapper {
              &:focus {
                box-shadow: none !important;
              }
            }
          }

          .ant-select {
            &.ant-select-focused {
              &:not(.ant-select-disabled).ant-select {
                &:not(.ant-select-customize-input) {
                  .ant-select-selector {
                    box-shadow: none !important;
                  }
                }
              }
            }
            &.ant-select-disabled {
              .ant-select-selector {
                color: $primaryTextColor;
              }
            }
            &.read-only {
              user-select: text !important;
              span {
                user-select: text !important;
              }
              .ant-select-selector {
                cursor: text;
                user-select: text !important;
                .ant-select-selection-item {
                  user-select: text !important;
                }
              }
            }
            .ant-select-selector {
              min-height: 40px;
              display: flex;
              align-items: center;
              border-color: $inputBorderColor;
              border-radius: 6px;
              .ant-select-selection-search {
                display: flex;
                align-items: center;
              }
            }
            &.ant-select-multiple {
              .ant-select-selector {
                min-height: 40px !important;
                height: 100% !important;
                .ant-select-selection-placeholder {
                  padding: 5px;
                }
                .ant-select-selection-overflow {
                  padding: 0px 5px;
                  overflow: auto;
                  .ant-select-selection-overflow-item {
                    .ant-select-selection-item {
                      background: $secondaryTextColor;
                      display: flex;
                      align-items: center;
                      padding: 12px 10px !important;
                      color: $sidebarColor;
                      border-radius: 6px !important;
                      border-color: transparent !important;
                      .ant-select-selection-item-content {
                        color: $sidebarColor;
                      }
                      .ant-select-selection-item-remove {
                        color: $sidebarColor;
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
            }
          }
          .ant-input {
            min-height: 40px;
            &:not(&:disabled) {
              background: transparent;
            }
            &:not(.ant-input-status-error) {
              border: 1px solid $inputBorderColor !important;
            }
            border-radius: 6px;
            &:focus {
              box-shadow: none !important;
            }
            &:disabled {
              color: $primaryTextColor;
            }
          }
          .ant-input-password {
            .ant-input {
              border: none !important;
            }
          }
          .ant-transfer-list {
            .ant-input {
              border: none !important;
            }
          }
        }
      }
    }
  }
  .ant-divider {
    margin: 0 0 8px;
    border-color: $primaryBorderColor;
  }
  .action-row {
    height: 40px;
    top: 0;
    position: absolute;
    display: flex;
    align-items: center;
    right: 16px;
    gap: 12px;
    .drag-icon {
      font-size: 16px;
      color: $primaryTextColor;
    }
  }
  .form-help-text {
    display: flex;
    gap: 8px;
    i {
      font-size: 18px;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.5px;
      text-align: left;
      color: $sidebarInactiveTextColor;
    }
  }
}
.mapping-field {
  .ant-select-selector {
    border-radius: 6px !important;
  }
  .ant-select-selection-item {
    background: $secondaryTextColor;
    display: flex;
    align-items: center;
    padding: 12px 10px !important;
    color: $sidebarColor;
    border-radius: 6px !important;
    border-color: transparent !important;
    .ant-select-selection-item-content {
      color: $sidebarColor;
    }
    .ant-select-selection-item-remove {
      color: $sidebarColor;
      font-size: 12px;
    }
  }
}
.form-footer {
  position: fixed !important;
  bottom: 0 !important;
  right: 0 !important;
  z-index: 9 !important;
  // width: calc(100% - 250px) !important;
  width: 100%;
  background: $layoutBackground;
  height: 90px;
  display: flex;
  align-items: center;
  padding: 0;
  transition: all 0.2s, background 0s;
  .footer-content {
    height: 60px;
    width: 100%;
    background: $sidebarColor;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 16px 24px !important;
    padding: 16px;
    border-radius: 6px;
  }
}
.log-list {
  .ant-card-body {
    font-size: 14px !important;
  }
}
.standalone-switch-container {
  display: flex;
  position: relative;
  width: 350px;
  .standalone-switch-wrapper {
    position: absolute;
    display: flex;
    right: 44px;
    top: -15px;
    .switch-wrap {
      display: flex;
    }
    .ant-form-item-label {
      display: flex;
      width: 110px;
      padding-bottom: 0 !important;
      label {
        font-size: 16px;
      }
    }
  }
}

.save-btn {
  border-radius: 6px;
  background: $secondaryTextColor;
  border-color: $secondaryTextColor;
  color: $sidebarColor;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.016em;
  text-align: left;
  padding: 0px 16px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  span {
    &.ant-modal-close-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &:hover,
  &:active,
  &:focus {
    background: $secondaryTextColor;
    color: $sidebarColor;
    border-color: $secondaryTextColor;
  }
}
.cancel-btn {
  border-radius: 6px;
  background: transparent;
  color: $secondaryTextColor;
  border: 1px solid $secondaryTextColor;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.016em;
  text-align: left;
  display: flex;
  align-items: center;
  &:hover,
  &:active,
  &:focus {
    color: $secondaryTextColor;
    border-color: $secondaryTextColor;
  }
}
.form-header-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: $secondaryTextColor;
  border: 1px solid $secondaryTextColor;
  border-radius: 6px;
  padding: 6px 12px;
  font-weight: 500;
  i {
    margin-right: 8px;
  }
  &:hover,
  &:active,
  &:focus {
    color: $secondaryTextColor;
    border-color: $secondaryTextColor;
  }
}

.ant-modal-root {
  .ant-modal-wrap {
    .ant-modal-content {
      border-radius: 6px;
      .ant-modal-header {
        border-radius: 6px 6px 0 0;
        border-bottom-color: $primaryBorderColor;
        .ant-modal-title {
          font-size: 18px;
          font-weight: 500;
          line-height: 30px;
          letter-spacing: 0em;
          text-align: left;
          color: $primaryTextColor;
          margin-right: 28px;
          .warning-header {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 10px;
            .warning-title {
              font-size: 18px;
              font-weight: 500;
              line-height: 30px;
              letter-spacing: 0em;
              margin-bottom: 0;
              color: #d14343;
              text-align: left;
              margin-top: 2.5px;
            }
            .warning-icon {
              span {
                height: 35px;
                width: 35px !important;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              display: flex;
              align-items: center;
              font-size: 18px;

              background: #fceeee;
              color: #d14343;
              border-radius: 50%;
              justify-content: center;
              outline: 5px solid #fdf4f4;
            }
          }
        }
      }
      .ant-modal-close {
        height: 63px;
        width: 63px;
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-modal-close-x {
          height: 35px;
          width: 35px;
          background: $secondaryTextColor;
          color: $sidebarColor;
          border-radius: 50%;
          position: relative;
          span {
            &.ant-modal-close-icon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
      .ant-modal-footer {
        padding: 16px 24px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-top-color: $primaryBorderColor;
        .ant-btn-default {
          background: transparent;
          border-color: $secondaryTextColor;
          color: $secondaryTextColor;
          padding: 8px 24px;
          text-align: center;
          border-radius: 6px;
          span {
            display: flex;
            align-items: center;
            height: 100%;
          }
        }
        .ant-btn-default {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .ant-btn-primary {
          background: $secondaryTextColor;
          border-color: $secondaryTextColor;
          color: $sidebarColor;
          padding: 8px 24px;
          text-align: center;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            &:not(.ant-btn-loading-icon) {
              display: flex;
              align-items: center;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

&.ant-scrolling-effect {
  width: 100% !important;
}

.import-modal {
  .import-json-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .download-btn-wrapper {
      margin-top: 20px;
    }

    .import-dragger {
      margin-top: 32px;
      .ant-upload-btn {
        padding-left: 24px !important;
        padding-right: 24px !important;
      }
    }

    .dropzone {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 60px;
      border: 1px solid $primaryBorderColor;
      border-radius: 6px;
      margin: 24px;
      margin-top: 28px;
      cursor: pointer;
      text-align: center;
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        color: $sidebarInactiveTextColor;
      }
    }
    .file-section {
      display: flex;
      flex-direction: row;
      gap: 8px;
      margin-top: 16px;
      .label,
      .file-name {
        color: $primaryTextColor;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
      }
    }
  }
  .title-col {
    p {
      margin-bottom: 0 !important;
    }
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: $secondaryTextColor;
    font-weight: 500;
    font-size: 14px;
    .title {
      display: flex;
      align-items: center;
      font-size: 14px;
      gap: 16px;
    }
    .ant-divider {
      margin: 8px 0;
      border-color: $secondaryTextColor;
    }
  }
  .mapping-section {
    margin-top: 24px;
  }
}

.manage-section-modal {
  .ant-modal-content {
    .ant-modal-body {
      max-height: 500px;
      overflow-y: auto;
    }
  }
}

.ant-tabs {
  color: $white;
  .ant-tabs-nav {
    margin-bottom: 24px;
    &::before {
      border-bottom: 1px solid $secondaryTextColor;
    }
    .ant-tabs-nav-list {
      .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 24px;
      }
      .ant-tabs-tab {
        .ant-tabs-tab-btn {
          color: $primaryTextColor;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.016em;
          text-align: left;

          &:hover {
            color: $secondaryTextColor !important;
          }
        }
      }
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: $secondaryTextColor !important;
        }
      }
      .ant-tabs-ink-bar {
        background: $secondaryTextColor !important;
        height: 2.5px !important;
      }
    }
    .ant-tabs-extra-content {
      .extra-tab-content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        margin-bottom: 8px;
        .import-btn {
          color: $secondaryTextColor;
        }
      }
    }
  }
}

.ant-drawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      .ant-drawer-header {
        border-bottom-color: $primaryBorderColor;
        .ant-drawer-header-title {
          position: relative;
          .ant-drawer-close {
            position: absolute;
            right: 0;
            margin-right: 0;
            height: 35px;
            width: 35px;
            background: $secondaryTextColor;
            color: $sidebarColor;
            border-radius: 50%;
            span {
              &.ant-modal-close-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
          .ant-drawer-title {
            margin-right: 40px;
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: left;
            color: $primaryTextColor;
          }
        }
      }
      .ant-drawer-body {
        @extend .custom-scrollbar;
      }
      .ant-drawer-footer {
        padding: 16px 24px;
        border-top-color: $primaryBorderColor;
      }
    }
  }
}

.ant-checkbox-wrapper {
  .ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      &::after {
        background-color: $secondaryTextColor;
      }
    }
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border-color: $secondaryTextColor;
      background-color: $secondaryTextColor;
    }
  }
}

.manage-options-drawer {
  .add-option-col {
    display: flex;
    justify-content: flex-end;
    button {
      margin-top: 29px;
    }
  }
  .add-option-btn {
    height: 40px;
    border-radius: 6px;
    background: transparent;
    color: $secondaryTextColor;
    border: 1px solid $secondaryTextColor;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.016em;
    padding: 0px 8px;
    text-align: left;
    i {
      margin-right: 8px;
    }
  }

  .info-section {
    .title {
      display: flex;
      align-items: center;
      gap: 8px;
      .title-text {
        margin-bottom: 0;
        color: $primaryTextColor;
      }
    }
    ul {
      margin-top: 8px;
      padding-inline-start: 22px;
      li {
        p {
          margin-bottom: 0;
          color: $primaryTextColor;
          font-size: 12px;
        }
      }
    }
  }
}

.sortableHelper {
  z-index: 10000 !important;
  cursor: pointer !important;
  pointer-events: all !important;
}

.option-list {
  margin-top: 16px;
}

.option-card {
  border-radius: 6px;
  box-shadow: -2px 4px 13px 0px #0000000f;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  &.default-option {
    border-color: $secondaryTextColor;
  }

  .ant-card-body {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    flex-wrap: wrap;
    width: 100%;

    .name-container {
      display: flex;
      align-items: center;
      margin-right: 20px;
    }
    .fa-grip-vertical {
      margin-right: 12px;
      color: $primaryTextColor;
      cursor: grab;
    }

    .fa-trash {
      cursor: pointer;
      color: #f24e44;
    }
    .delete-btn {
      margin-left: auto;
    }
    .option-name {
      margin-bottom: 0;
      margin-top: 0;
      word-wrap: break-word;
      width: calc(100% - 40px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.ant-transfer {
  width: 100%;

  &:not(.ant-transfer-status-error) {
    .ant-transfer-list {
      border-color: $inputBorderColor !important;
    }
  }
  .ant-transfer-list {
    width: 49%;
    height: 350px;
    border-radius: 6px;
    .ant-transfer-list-header {
      border-radius: 6px 6px 0 0;
      border-color: $inputBorderColor;
      .ant-checkbox-wrapper {
        margin-right: 16px;
      }
    }
    .ant-transfer-list-body {
      .ant-transfer-list-body-search-wrapper {
        .ant-input-affix-wrapper {
          height: 40px;
          display: flex;
          align-items: center;
          border-radius: 6px;
          input {
            border: none !important;
          }
          &:hover,
          &:active,
          &:focus {
            box-shadow: none;
          }
          &:not(.ant-input-affix-wrapper-status-error) {
            &:hover,
            &:active,
            &:focus {
              border-color: $inputBorderColor;
            }
          }
          &.ant-input-affix-wrapper-focused {
            box-shadow: none;
            border-color: $inputBorderColor;
          }
        }
      }
      .ant-transfer-list-content {
        padding-bottom: 8px;
        .ant-transfer-list-content-item {
          &.ant-transfer-list-content-item-checked {
            background-color: rgba($secondaryTextColor, 0.1);
          }
          .ant-transfer-list-content-item-text {
            color: $primaryTextColor;
            .transfer-item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .drag-handle {
                color: $primaryTextColor;
                cursor: grab;
              }
              .product-name {
                width: calc(100% - 25px);
                white-space: pre-wrap;
              }
            }
          }
        }
        @extend .custom-scrollbar;

        &.ant-scrolling-effect {
          width: 100% !important;
        }
      }
    }
  }
  .ant-transfer-operation {
    width: 2%;
    margin: 0 16px;
    display: flex;
    align-items: center;
    .ant-btn {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      margin-bottom: 16px;
      &:not(:disabled) {
        background-color: $secondaryTextColor;
        border-color: $secondaryTextColor;
        color: $sidebarColor;
      }
    }
  }
}

.delete-popup {
  .ant-modal-content {
    .ant-modal-header {
      .ant-modal-title {
        color: #d14343 !important;
      }
    }
  }
}

.warning-popup {
  .ant-modal-content {
    .ant-modal-header {
      .ant-modal-title {
        color: #f0ad4e !important;
      }
    }
  }
}

.welcome-popup {
  .welcome-popup-wrapper {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    img {
      height: 150px;
      width: 150px;
    }
    svg {
      height: 70px;
      width: 70px;
      color: $secondaryTextColor;
    }
    p {
      margin-bottom: 0px !important;
      text-align: center;
    }
    .header {
      font-size: 24px;
      margin-bottom: 0;
      text-align: center;
      font-weight: 600;
    }
    .welcome-title {
      font-size: 24px;
      margin-bottom: 0;
      text-align: center;
      span {
        font-weight: 600;
      }
    }
    .info-text {
      font-size: 18px;
    }
    .save-btn {
      margin-left: 0 !important;
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
}

.ant-table {
  .custom-empty-text {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 0;
    font-size: 16px;
    font-weight: 500;
    color: $primaryTextColor;
    .link {
      color: $secondaryTextColor;
      cursor: pointer;
      margin-left: 3px;
    }
  }
}

.notification-popover {
  .ant-popover-content {
    .ant-popover-arrow {
      .ant-popover-arrow-content {
        &::before {
          background: $secondaryTextColor !important;
        }
      }
    }
    .ant-popover-inner {
      min-width: 400px;
      max-width: 400px;
      border-radius: 6px;
      .ant-popover-title {
        padding: 12px 12px 12px 20px;
        color: $sidebarColor;
        border-bottom-color: $primaryBorderColor;
        font-size: 16px;
        background: $secondaryTextColor;
        color: $sidebarColor;
        border-radius: 6px 6px 0 0;
        .notification-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .title {
            margin-bottom: 0;
          }
          .notification-count {
            background: $sidebarColor;
            border-radius: 6px;
            color: $secondaryTextColor;
            padding: 4px 8px;
            font-size: 13px;
            font-weight: 600;
          }
        }
      }
      .ant-popover-inner-content {
        padding: 0;
        width: 100%;
        .notification-content {
          .notification-list {
            display: flex;
            flex-direction: column;
            max-height: 400px;
            overflow-y: auto;
            padding: 8px;

            @extend .custom-scrollbar;

            .notification-item {
              padding: 8px 12px;

              .header {
                display: flex;
                align-items: center;
                .ant-avatar {
                  margin-right: 8px;
                  height: 32px !important;
                  width: 32px !important;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: #d4e8f3d1;
                  .ant-avatar-string {
                    color: #299cdb;
                  }
                }
                margin-bottom: 4px;
              }
              cursor: pointer;
              p {
                font-weight: 700;
                margin-bottom: 0;
                word-wrap: break-word;
                font-size: 14px;
                span {
                  font-weight: 400;
                  color: #72849a !important;
                }
              }

              .event {
                padding-left: 40px;
                .event-info {
                  display: flex;
                }
                p {
                  font-size: 13px;
                }
                .ant-divider {
                  margin: 4px 0;
                  border-color: $primaryBorderColor;
                }
              }

              .date {
                color: #1f2937bf;
                font-size: 13px;
                font-weight: 400;
                margin-left: auto;
                margin-top: auto;
                white-space: pre;
              }
              &:hover {
                background: rgba($tableRowColor, 0.7);
              }
            }
          }
          .empty-notification-cell {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px 16px;
            font-size: 16px;
            height: 100px;
            p {
              margin-bottom: 0;
            }
          }
          button {
            border: none;
            padding: 12px;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $secondaryTextColor;
            font-weight: 500;
            font-size: 14px;
            border-radius: 0 0 6px 6px;
            width: 100%;
            border-top: 1px solid $primaryBorderColor;
            &:hover,
            &:focus,
            &:active {
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}

.ant-spin-nested-loading {
  height: 100%;

  .ant-spin-container {
    height: 100%;
    &::after {
      display: none;
    }
  }
}

.ant-tag {
  box-shadow: none;
  border-radius: 6px !important;
  font-size: 13px !important;
  font-weight: 500;
  padding: 2px 10px !important;
  background: rgba($secondaryTextColor, 0.1);
  color: rgba($secondaryTextColor, 0.8);
  border-color: rgba($secondaryTextColor, 0.7);
  display: flex;
  align-items: center;
  width: fit-content;
  .anticon-close {
    color: rgba($secondaryTextColor, 0.8);
  }
  p {
    margin-bottom: 0;
  }
  &.active {
    background: #edfcf2;
    color: #38a071;
    border-color: rgba(#38a071, 0.5);
    .anticon-close {
      color: #38a071;
    }
  }
  &.inactive {
    background: #fceff6;
    color: #e97a9d;
    border-color: rgba(#e97a9d, 0.5);
    .anticon-close {
      color: #e97a9d;
    }
  }
  &.error {
    color: #dc3838;
    border-color: #dc3838;
    background: #fff5f5;
    .ant-close {
      color: #dc3838;
    }
  }
  &.warning {
    color: orange;
    border-color: orange;
    background: rgb(249, 233, 204);
    .ant-close {
      color: orange;
    }
  }
}
.ant-popover-inner-content {
  padding: 4px 10px !important;
}

.gear-btn {
  i {
    color: #1976d2;
  }
  border: none;
  background: transparent;
  font-size: 16px !important;
}
.gear-btn:hover {
  background: transparent !important;
}
.gear-btn:active {
  background: transparent !important;
}
.gear-btn:focus {
  background: transparent !important;
}
.disable-menu {
  pointer-events: none;
  color: grey;
  cursor: not-allowed !important;
}
.pop-over-menu-item {
  i {
    margin-right: 10px;
    font-size: 12px;
  }
}
.alert-title {
  font-weight: 550;
}
.pop-over-menu-item {
  padding-left: 4px !important;
  height: 24px !important;
  font-size: 13px;
  font-weight: 500;
}
.question-alert-pop-up {
  .partner-title {
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: 600;
  }
  .partner-list {
    display: flex;
    align-items: center;
    row-gap: 8px;
    flex-wrap: wrap;
  }
}

.provider-statuses {
  display: flex;
  row-gap: 8px;
  flex-wrap: wrap;
}

.access-control-table {
  .ant-table {
    .ant-table-container {
      .ant-table-content {
        tr {
          .ant-table-cell {
            background-color: transparent !important;
            font-size: $tableCellFontSize;
            padding: 16px 8px !important;
          }
          &.ant-table-row {
            &:nth-child(even) {
              background-color: transparent !important;
            }
          }
        }
        .ant-table-thead {
          .ant-table-cell {
            &::before {
              display: none;
            }
            font-size: $tableCellFontSize;
            font-weight: 600;
            border-bottom: none !important;
          }
        }
      }
      .ant-table-tbody {
        .ant-table-row {
          &:hover {
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1) !important;
          }
        }
      }
      .ant-radio-group {
        font-weight: 400;
        .ant-radio-button-wrapper {
          font-size: $tableCellFontSize;
          &:hover {
            color: inherit;
          }
          &.ant-radio-button-wrapper-checked {
            border-color: $secondaryTextColor;
            background-color: $secondaryTextColor;
            color: $sidebarColor;
            &:not(.ant-radio-button-wrapper-disabled) {
              &:before {
                display: none;
              }
            }
          }
          &:first-child {
            border-radius: 6px 0 0 6px;
          }
          &:last-child {
            border-radius: 0 6px 6px 0;
          }
        }
      }
    }
  }
}

.permission-info {
  .title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 600;
    color: $secondaryTextColor;
    font-size: 16px;
    .title-text {
      margin-bottom: 0;
    }
  }
  ul {
    margin-top: 8px;
    padding-inline-start: 22px;
    margin-bottom: 0;
    li {
      margin-bottom: 8px;
      p {
        margin-bottom: 0;
        color: $primaryTextColor;
        font-size: 14px;
        .permission-name {
          font-weight: 600;
          margin-right: 4px;
        }
      }
    }
  }
}

.event-table {
  .ant-table {
    .ant-table-container {
      .ant-table-content {
        .ant-table-thead {
          .ant-table-cell {
            background: $tableRowColor;
            color: $primaryTextColor;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            padding: 12px 18px;
            &::before {
              display: none;
            }
          }
        }
        .ant-table-tbody {
          tr {
            &:nth-child(even) {
              &:hover {
                .ant-table-cell {
                  background-color: $sidebarColor;
                  background: $sidebarColor;
                }
              }
            }
            &:hover {
              box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1) !important;
            }
          }
          .ant-table-row {
            &:nth-child(odd) {
              .ant-table-cell {
                background-color: rgba($tableRowColor, 0.5) !important;
                background: rgba($tableRowColor, 0.5) !important;
              }
            }
          }
        }
        .ant-table-tbody {
          .ant-table-row {
            &:nth-child(odd) {
              .ant-table-cell {
                background-color: rgba($tableRowColor, 0.5) !important;
                background: rgba($tableRowColor, 0.5) !important;
              }
            }
            .ant-table-cell {
              background: transparent !important;
              &.event-name {
                text-transform: capitalize;
              }
              padding: 16px;
            }
          }
        }
      }
    }
  }
}
.ant-select-dropdown {
  .select-option-placeholder {
    font-weight: 500 !important;
    padding: 5px 12px !important;
    color: $primaryTextColor !important;
  }
}
.drag-line {
  border-bottom-color: $secondaryTextColor !important;
}

.ant-dropdown {
  .ant-table-filter-dropdown {
    max-width: 320px;
    width: 320px;
    overflow-x: auto;
    border-radius: 6px;
    .ant-table-filter-dropdown-search {
      padding: 12px;
      .table-dropdown-filter {
        border-radius: 6px;
      }
      .ant-table-filter-dropdown-search-input {
        border-radius: 6px;
      }
    }
    .filter-list {
      padding: 8px 12px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-height: 300px;
      overflow-y: auto;

      .ant-checkbox-wrapper {
        &:not(.select-all-checkbox) {
          margin-left: 24px;
        }
      }
    }
    .ant-table-filter-dropdown-btns {
      padding-left: 12px;
      padding-right: 12px;
      .ant-btn-primary {
        background: $secondaryTextColor;
        border-color: $secondaryTextColor;
        color: $sidebarColor;
        font-size: 14px;
        padding: 0 12px;
        border-radius: 6px;
      }
    }
  }
}
.ant-picker-range-wrapper {
  .ant-picker-panel-container {
    .ant-picker-footer {
      .ant-picker-ranges {
        .ant-picker-preset {
          .ant-tag {
            font-size: 12px !important;
            padding: 2px 8px !important;
          }
        }
      }
    }
  }
}

.home {
  .home-icon {
    background: $sidebarColor;
    border: 2px solid rgba(0, 0, 0, 0.08) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    border-radius: 6px;
    padding: 24px 16px;
    height: 100%;
    cursor: pointer;
    font-weight: 600;
    color: $primaryTextColor;
    i {
      font-size: 34px;
      display: block;
      color: rgba($secondaryTextColor, 0.8);
      margin-bottom: 12px;
    }
    &:hover {
      border-color: rgba($secondaryTextColor, 0.8) !important;
      color: rgba($secondaryTextColor, 0.8) !important;
      background-color: rgba($secondaryTextColor, 0.01) !important;
    }
    &:focus {
      transform: scaleY(50%);
    }
  }
}

.add-btn {
  background-color: transparent !important;
  color: $secondaryTextColor !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: 1px solid $secondaryTextColor !important;
  border-radius: 6px !important;
  padding: 0 12px !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  &.more-filter {
    i {
      margin-right: 0px !important;
      margin-left: 8px;
    }
  }
  i {
    margin-right: 8px !important;
    font-size: 16px !important;
  }
  &:hover,
  &:active {
    background-color: transparent !important;
  }
}
.ack-btn {
  border-radius: 6px !important;
  border: 1px solid $secondaryTextColor !important;
  color: $secondaryTextColor !important;
}
.ack-head {
  font-weight: 550;
  font-size: 15px;
}
.ack-sub {
  margin-top: 16px;
  font-size: 13.5px;
  font-weight: 450;
}
.ack-popup {
  .ant-modal-content {
    .ant-modal-header {
      .ant-modal-title {
        color: $secondaryTextColor !important;
      }
    }
  }
}
.create-transaction-modal {
  .loan-number-text {
    margin-bottom: 0;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 500;
    color: $primaryTextColor;
  }
}

.user-profile {
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      .ant-drawer-header {
        background: rgba($secondaryTextColor, 0.15);
        .ant-drawer-header-title {
          align-items: flex-start;
          text-align: center;
          .ant-drawer-close {
            z-index: 999;
          }
          .ant-drawer-title {
            margin-right: 0 !important;
          }
          .ant-avatar {
            margin: 10px auto;
            left: 50%;
            transform: translateX(-50%);
            height: 70px;
            width: 70px;
            background: #f8f8f8 !important;
            box-shadow: -2px 4px 17px 0px rgba(0, 0, 0, 0.1) !important;
            color: $secondaryTextColor;

            i {
              font-size: 70px;
            }
          }
          .username {
            margin-bottom: 8px;
            font-size: 18px;
            display: block;
            text-align: center;
            color: $secondaryTextColor;
          }
          .user-meta-info {
            font-size: 13px;
            line-height: 16px;
            margin-bottom: 3px;
            text-align: center;
            color: $primaryTextColor;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
              margin-right: 6px;
              font-size: 16px;
            }
          }
        }
      }
      .ant-drawer-body {
        .ant-list {
          .ant-list-items {
            .ant-list-item {
              display: flex;
              justify-content: flex-start;
              gap: 12px;
              color: $primaryTextColor;
              font-weight: 500;
              font-size: 15px;
              cursor: pointer;
              &:hover {
                color: $secondaryTextColor;
              }
            }
          }
        }
      }
      .ant-drawer-footer {
        .cancel-btn {
          width: 100%;
          justify-content: center;
          i {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.log-accordion {
  background-color: transparent !important;
  border: none !important;
  .ant-collapse-item {
    margin-bottom: 16px;
    border: none;
    border-radius: 6px !important;
    background-color: rgba($tableRowColor, 1);

    .ant-collapse-header {
      color: $primaryTextColor;
      text-transform: capitalize;
      font-weight: 500;
      .ant-collapse-extra {
        margin-right: 4px;
      }
    }
    .ant-collapse-content {
      color: $primaryTextColor;
      border-color: rgba($tableRowColor, 1);
    }
    .ant-collapse-content-active {
      border-top: none !important;
      .ant-collapse-content-box {
        border-radius: 0 0 6px 6px;
        border: 1px solid rgba($tableRowColor, 1);
        border-top: none;
        max-height: 500px;
        overflow-y: auto;
        .pretty-json-container {
          .object-content {
            .object-key-val {
              .pushed-content {
                .object-content {
                  .variable-row {
                    word-break: break-all;
                  }
                }
              }
            }
          }
        }
        .rxv-container {
          word-break: break-all;
          padding-left: 12px;

          div {
            color: #d0d0d0;
          }
        }
        @extend .custom-scrollbar;
      }
    }
  }
}

.ant-upload {
  &.ant-upload-drag {
    border-radius: 6px;
    border-color: $inputBorderColor;
    &:not(.ant-upload-disabled) {
      &:hover {
        border-color: $secondaryTextColor;
      }
    }
    .ant-upload {
      .ant-upload-drag-container {
        .ant-upload-drag-icon {
          .anticon-inbox {
            color: $secondaryTextColor;
          }
        }
        p {
          &.ant-upload-text {
            font-size: 15px;
          }
          &.ant-upload-hint {
            font-size: 13px;
          }
        }
      }
    }
  }
}

.ant-upload-list {
  &.ant-upload-list-picture-card {
    .ant-upload-list-picture-card-container {
      margin-top: 16px;
      width: 100%;
      .ant-upload-list-item {
        border-color: $inputBorderColor;
        border-radius: 6px;
        .ant-upload-list-item-info {
          border-radius: 6px;
        }
        .ant-upload-list-item-actions {
          a {
            margin-right: 8px;
          }
        }
      }
    }
  }
  &.ant-upload-list-picture {
    .ant-upload-list-picture-container {
      .ant-upload-list-item {
        margin-top: 16px;
        border-color: $inputBorderColor;
        border-radius: 6px;
        .ant-upload-list-item-info {
          .ant-upload-span {
            .ant-upload-list-item-name {
              color: rgba($secondaryTextColor, 0.8);
              padding-left: 16px;
              font-weight: 600;
              text-align: end;
              cursor: pointer;
              &:active {
                color: $secondaryTextColor;
              }
            }
          }
        }
      }
    }
  }
}

.ant-upload-list-text {
  color: $primaryTextColor;
  .ant-upload-list-text-container {
    .ant-upload-list-item-list-type-text {
      .ant-upload-list-item-info {
        height: 30px;
        .ant-upload-span {
          padding: 0 8px;
          .ant-upload-list-item-card-actions {
            .ant-upload-list-item-card-actions-btn {
              .anticon-delete {
                color: #dc3838;
              }
            }
          }
        }
      }
    }
  }
}

.img-crop-modal {
  .ant-btn {
    &:not(.default-modal-btn) {
      position: absolute;
      background: transparent;
      border-color: $secondaryTextColor;
      color: $secondaryTextColor;
      padding: 8px 24px;
      text-align: center;
      border-radius: 6px;
      span {
        display: flex;
        align-items: center;
        height: 100%;
      }
    }
  }
}

.ant-segmented {
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid $secondaryTextColor;
  border-radius: 6px;
  padding: 0px 4px;
  background: $sidebarColor;
  &:not(.ant-segmented-disabled) {
    &:hover {
      background-color: $sidebarColor;
    }
  }

  .ant-segmented-group {
    .ant-segmented-item {
      color: $primaryTextColor;
      transition: color 0s !important;

      &.ant-segmented-item-selected {
        border-radius: 6px;
        color: $sidebarColor;
        &:hover {
          color: $sidebarColor;
        }
      }
      .ant-segmented-item-label {
        height: 100%;
        display: flex;
        align-items: center;
        min-height: 30px;
      }
    }
  }
}

.ant-segmented-item-selected {
  background-color: $secondaryTextColor;
}

.ant-segmented-thumb {
  background-color: $secondaryTextColor;
  border-radius: 6px;
  color: $sidebarColor;
}

.card-title-with-icon {
  display: flex;
  align-items: center;
  color: $secondaryTextColor;
  gap: 8px;
  &.error-title {
    color: #dc3838;
  }
  p {
    margin-bottom: 0;
  }
}

.request-card {
  border-radius: 6px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.03);
  .ant-card-head {
    .ant-card-head-wrapper {
      .ant-card-head-title {
        padding: 12px 0;
        color: $secondaryTextColor;
        @extend .card-title-with-icon;
      }
    }
  }
  .ant-card-body {
    padding: 0;
    border-top: 1px solid $primaryBorderColor;
    .ant-table-wrapper {
      &.log-request-info {
        border-radius: 6px;
        .ant-table {
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
          background: rgba($sidebarColor, 1);
          .ant-table-container {
            .ant-table-content {
              .ant-table-tbody {
                .ant-table-row {
                  .ant-table-cell {
                    padding: 12px 24px;
                    background: transparent !important;
                    color: $primaryTextColor;
                    border-bottom: 1px solid $primaryBorderColor;
                    &:not(.label) {
                      word-break: break-all;
                    }
                    &.label {
                      font-weight: 600;
                    }
                    &.ant-table-cell-row-hover {
                      background: transparent !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.info-card {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.03);
  // height: 100%;
  .ant-card-head {
    padding: 0 24px;
    border-bottom-color: $primaryBorderColor;
    .ant-card-head-wrapper {
      .ant-card-head-title {
        color: $secondaryTextColor;
        padding: 12px 0;
        @extend .card-title-with-icon;
      }
    }
  }
  .ant-card-body {
    padding: 16px 24px;
    font-size: 16px;
  }
}

.request-details-card {
  border-radius: 6px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.03);
  .ant-card-head {
    .ant-card-head-tabs {
      .ant-tabs-nav {
        .ant-tabs-nav-wrap {
          .ant-tabs-nav-list {
            .ant-tabs-tab {
              padding: 12px 0;
              .ant-tabs-tab-btn {
                font-size: 14px;
                font-weight: 500;
              }
            }
          }
        }
        .ant-tabs-extra-content {
          .ant-btn {
            color: $primaryTextColor;
          }
        }
        &::before {
          border-color: $primaryBorderColor !important;
        }
        margin-bottom: 17px !important;
      }
    }
  }
  .ant-card-body {
    background: $black;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    max-height: 400px;
    overflow-y: auto;
    @extend .custom-scrollbar !optional;
  }
}
.rxv-container {
  word-break: break-all;
  padding-left: 12px;

  div {
    color: #d0d0d0;
  }
}

.log-summary-accordion {
  background-color: $sidebarColor;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  &.address-error-accordion {
    border-radius: 6px;
    .ant-collapse-item {
      border-color: $primaryBorderColor;
      border-radius: 0 0 6px 6px;

      .ant-collapse-header {
        font-size: 16px;
        .ant-collapse-expand-icon {
          color: #dc3838;
        }
      }
      .ant-collapse-content {
        border-radius: 0 0 6px 6px;
      }
    }
  }
  .ant-collapse-item {
    border-color: $primaryBorderColor;
    .ant-collapse-header {
      padding: 12px 24px;
      color: $secondaryTextColor;
      font-weight: 600;
      align-items: center;
    }
    .ant-collapse-content {
      background: $black;
      .ant-collapse-content-box {
        padding: 24px;
        max-height: 400px;
        overflow-y: auto;
        @extend .custom-scrollbar !optional;
      }
    }
  }
}

.public-layout {
  .ant-layout-header {
    width: 100% !important;
  }
}

.verify-btn {
  color: #1976d2;
  border-color: #1976d2;
  border-radius: 4px;
  &:hover,
  &:active,
  &:focus {
    border-color: #1976d2;
    color: #1976d2;
  }
}
.decline-btn {
  color: rgb(220, 56, 56);
  border-color: rgb(220, 56, 56);
  margin-left: 4px;
  border-radius: 4px;
  &:hover,
  &:active,
  &:focus {
    color: rgb(220, 56, 56);
    border-color: rgb(220, 56, 56);
  }
}
.ml-10 {
  margin-left: 12px !important;
}
.w-95 {
  width: 95% !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.request-details-card,
.request-card {
  .accordion-content {
    .details {
      color: $sidebarColor;
    }
  }
}
.user-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #1976d2;
  padding: 0px 8px 0px 4px;
  border-radius: 20px;
  line-height: 35px !important;
  cursor: pointer;
}
.user-name {
  font-weight: 500;
  font-size: 13px;
}
.react-json-view {
  .pretty-json-container {
    .object-content {
      .object-key-val {
        .variable-row {
          .variable-value {
            word-break: break-all;
          }
        }
      }
    }
  }
}

.title-form {
  .disabled-field .ant-form-item-label label {
    color: rgba(0, 0, 0, 0.25) !important;
  }
}
.error-detail-card {
  .ant-card-head {
    .ant-card-head-wrapper {
      .ant-card-head-title {
        position: relative;
        padding: 12px 0;
        .copy-btn {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  .ant-card-body {
    height: 100%;
  }
}

.card-title-with-icon {
  display: flex;
  align-items: center;
  color: $secondaryTextColor;
  gap: 8px;
  &.error-title {
    color: #dc3838;
  }
  p {
    margin-bottom: 0;
  }
}

.module-categorization {
  margin-top: 32px;
  margin-bottom: 16px;

  .category-title-wrapper {
    font-size: 22px;
    font-weight: 500;
    color: $secondaryTextColor;

    i {
      font-size: 22px;
    }
    p {
      margin-bottom: 0;
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
    }
  }

  .category-row {
    &:not(&:first-child) {
      margin-top: 24px;
    }
  }
}
.title-calculator {
  .ant-layout {
    padding-top: 0px !important;
    .content-layout {
      width: 1300px !important;
      // margin-left: 10% !important;
      margin: 0 auto !important;
    }
    .ant-layout-header {
      position: unset !important;
      .header-content {
        margin: 0 !important;
      }
    }
    &.sider-collapsed {
      .site-layout {
        padding-left: 0px !important;
        .ant-layout-header {
          margin: 0 auto !important;
          width: 1300px !important;
          // left: 10% !important;
        }
        .form-footer {
          width: 1300px !important;
        }
      }
    }
  }
}
.category-card {
  box-shadow: -5px 3px 13px 2px rgba(0, 0, 0, 0.0588235294) !important;
  margin-left: 2px;
  height: 100%;
  overflow: hidden;
  transition: all ease 0.28s, border 0s !important;
  border-radius: 6px;
  border-color: #ebebeb;
  &:hover {
    border: 1px solid transparent;
    transform: translateY(-4px);
    box-shadow: 0px 3px 8px -4px rgba(0, 0, 0, 0.1215686275),
      -1px 5px 18px 2px rgba(0, 0, 0, 0.0823529412),
      0 5px 20px 4px rgba(0, 0, 0, 0.0705882353) !important;
  }

  &.vertical-button {
    .ant-card-body {
      p {
        margin-bottom: 0;
      }
      display: flex;
      flex-direction: column;
      gap: 0;
      padding: 22px;
      i {
        margin-bottom: 12px;
      }
    }
  }

  .ant-card-body {
    padding: 22px;
    display: flex;
    align-items: center;
    gap: 24px;
    background: $sidebarColor;
    border-radius: 6px;
    display: flex;
    align-items: center;
    font-size: 18px;
    height: 100%;
    cursor: pointer;
    font-weight: 500;
    color: $primaryTextColor;
    word-break: break-word;
    text-align: center;

    &:hover {
      color: $secondaryTextColor;
      // background-color: #dfefff;
    }

    i {
      font-size: 30px;
      color: $secondaryTextColor;
    }
  }
}

.sidebar-label-with-title {
  flex: auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}


// some common classes

.f-14 {
  font-size: 14px !important;
}

.f-13 {
  font-size: 13px !important;
}
.f-12 {
  font-size: 12px !important;
}

.f-13-5 {
  font-size: 13.5px !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-450 {
  font-weight: 450 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-550 {
  font-weight: 550 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.color-e {
  color: red;
}

.color-w {
  color: orange !important;
}

.color-s {
  color: green !important;
}

.color-p {
  color: #005fd4 !important;
}

.color-g {
  color: grey !important;
}

// Margin Top
.mt-0 {
  margin-top: 0px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

@media (width < 1300px) {
  .title-calculator {
    .ant-layout {
      .content-layout {
        width: 100% !important;
        margin-left: 0% !important;
      }
      .ant-layout-header {
        .header-content {
          margin: 16px 24px !important;
        }
      }
      &.sider-collapsed {
        .site-layout {
          // padding-left: 0px !important;
          .ant-layout-header {
            width: 100% !important;
            left: 0% !important;
          }
          .form-footer {
            width: 100% !important;
          }
        }
      }
    }
  }
}
@media (width < 800px) {
  .title-form,
  .title-cal-form {
    .ant-col {
      height: unset !important;
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 1536px) {
  .container {
    padding-inline-end: 0.75rem;
    padding-inline-start: 0.75rem;
    padding-right: calc(12rem * 0.5) !important;
    padding-left: calc(12rem * 0.5) !important;

    .timeline {
      li {
        .activity-body {
          &::before {
            inset-inline-start: -19px !important;
            inset-block-start: 25px;
          }
        }
      }
    }
  }
}
.aol-title {
  display: flex;
  flex-direction: row;
}
.state-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .ant-tag {
    padding: 0px 6px !important;
    font-size: 11px !important;
  }
  .ant-tag.error {
    border-color: transparent !important;
  }
}
.config-selection-popup {
  .ant-modal-content {
    background: $layoutBackground;
    @extend .home;
    padding-top: 0;
    .ant-modal-body {
      padding-top: 0;
    }
  }
}
.empty-table-data {
  color: $primaryTextColor;
  text-align: left;
}
.configuration-switch {
  background-color: $secondaryTextColor;
}

.phone-input {
  letter-spacing: 2px;
}

.mb-8 {
  margin-bottom: 8% !important;
}

@media only screen and (max-width: 1024px) {
  .container {
    padding-inline-end: 0.75rem;
    padding-inline-start: 0.75rem;
    padding-right: calc(1.5rem * 0.5) !important;
    padding-left: calc(1.5rem * 0.5) !important;
    .timeline {
      li {
        .activity-body {
          margin-inline-start: 25% !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .manage-options-drawer {
    .add-option-col {
      button {
        margin-top: 0 !important;
      }
    }
  }
}
